import { resultsPageTemplate } from './base'

export const ResultsPageTemplate = resultsPageTemplate.defineMultiStyleConfig({
  baseStyle: resultsPageTemplate.definePartsStyle({
    searchForm: {
      boxShadow: '0 6px 9px 0 rgba(0,0,0, 0.1)',
      backgroundColor: 'bg.layer2.default',
    },
    searchTitle: {
      fontSize: 'var(--alto-sem-text-heading-lg-font-size);',
    },
    searchSubtitle: {
      fontSize: 'var(--alto-sem-text-body-md-font-size);',
    },
    resultsPagination: {
      justifyContent: 'flex-end',
    },
    sortListBox: {
      'div:first-child': {
        background: 'unset',
      },
      ul: {
        background: 'bg.white.vibrant.default',
        paddingLeft: 'sm',
      },
    },
    mapViewWrapper: {
      borderRadius: 'sm',
    },
    sidebar: {
      background: 'transparent',
    },
    skipLink: {
      borderRadius: 'sm',
      outlineColor: 'bg.accent.deep.default',
    },
    shareButtonContainer: {
      alignItems: { base: 'flex-start', lg: 'flex-end' },
    },
  }),
})
