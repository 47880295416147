/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const fgPrimary = 'fg.primary'

export const ExperienceDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    firstPart: {
      gap: {
        base: 'lg',
        md: '2xl',
      },
      marginTop: 'lg',
    },
    divider: {
      borderColor: 'transparent',
    },
    experienceOverviewTitle: {
      fontSize: 'var(--alto-sem-text-heading-xl-font-size)',
      color: fgPrimary,
    },
    experienceOverviewContainer: {
      padding: 0,
      borderRadius: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    sectionTitle: {
      marginBottom: 'sm',
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
    },
    showMoreButton: {
      border: '1px solid var(--alto-sem-color-border-accent)',
      borderColor: 'border.accent',
      _hover: {
        bg: 'bg.accent.subtle.default',
      },
    },
    experienceOverviewDescription: {
      mt: 'sm',
      fontSize: 'var(--alto-sem-text-body-lg-font-size)',
    },
    experienceInformationTitle: {
      color: fgPrimary,
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      fontWeight: 'var(--alto-sem-text-body-bold-font-weight)',
    },
    experienceInformationContainer: {
      color: fgPrimary,

      '> ul': {
        mt: 'lg',
      },
    },
  },
})
