import { itineraryHelpers } from './base'

export const Itinerary = itineraryHelpers.defineMultiStyleConfig({
  baseStyle: {
    property: {
      p: {
        fontSize: 'sm',
        color: 'fg.secondary',
      },
    },
    title: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
    },
    itemHeader: {
      h3: {
        fontSize: 'lg',
      },
      button: {
        fontSize: 'sm',
        marginLeft: 'auto',
      },
    },
    roomSummary: {
      color: 'fg.accent.onSubtle',
      fontSize: 'sm',
    },
    itemWrapper: {
      h4: {
        color: 'fg.secondary',
      },
    },
  },
})
