/* eslint-disable sonarjs/no-duplicate-string */
import { replaceHotelItemCardHelper } from './base'

export const ReplaceHotelItemCard = replaceHotelItemCardHelper.defineMultiStyleConfig({
  baseStyle: replaceHotelItemCardHelper.definePartsStyle({
    content: {
      img: {
        borderRadius: ' lg',
      },
    },
  }),
})
