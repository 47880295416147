import { helpers } from './base'

export const Hero = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      borderRadius: {
        base: 'none',
        md: 'xs',
      },
      margin: { base: '0', md: 'var(--alto-sem-space-xs)' },
    },
    heading: {
      color: 'fg.accent.onVibrant',
      fontSize: {
        base: 'var(--alto-sem-text-heading-lg-font-size)',
        md: 'var(--alto-sem-text-heading-xl-font-size)',
      },
      fontWeight: 'normal',
      lineHeight: '120%',
      paddingLeft: { base: 0, sm: 'var(--alto-sem-space-4xs)', lg: 'var(--alto-sem-space-2xs)' },
    },
    tagline: {
      color: 'fg.accent.onVibrant',
    },
  },
})
