import { fieldSetHelper } from './base'

export const Fieldset = fieldSetHelper.defineMultiStyleConfig({
  baseStyle: {
    visibleLegend: {
      fontSize: 'xl',
      fontWeight: 'semibold',
    },
  },
})
