import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import {
  DataLayerObject,
  resetAndPushNewPageView,
  compareAndPushEventInDataLayer,
  pushToDataLayer,
} from '~/utils/googleTagManager'
import { isProduction } from '~/utils/envChecks'
import { TagManagerContext, PushEventToDataLayer } from './TagManagerContext'
import { useAccountQuery, useIsAuthenticatedUser, useUserQuery } from '../user'
import { useChannel } from './useChannel'
import { usePartner } from '../partner'

type Event = {
  eventName: string
  data?: DataLayerObject
}

export const TagManagerProvider = ({ children }: { children?: React.ReactNode }) => {
  const router = useRouter()
  const [isPageViewEventSent, setIsPageViewEventSent] = useState(false)
  const [waitForPageView, setWaitForPageView] = useState(false)
  const isInitialEventSentRef = useRef(false)
  const isInitialGlobalEventSentRef = useRef(false)
  const [bufferedEvents, setBufferedEvents] = useState<Event[]>([])

  const userIsAuthenticated = useIsAuthenticatedUser()
  const { data: user, isLoading: isLoadingUser } = useUserQuery()
  const { data: accountsResponse, isLoading: isLoadingAccount } = useAccountQuery()
  const memberIdentifier = user?.memberIdentifier?.identifier
  const userBalance = accountsResponse?.accounts?.[0].balance.amount
  const iso = user?.person.countryOfResidence.identifier
  const channel = useChannel()
  const partner = usePartner()

  const sendGlobalEvent = useCallback(() => {
    const globalEvent: any = {
      isLoggedIn: userIsAuthenticated,
      Iso: iso,
      product: 'reward_platform',
      programmeCode: partner?.tag,
      memberID: memberIdentifier,
      balance: userBalance,
      domain_environment: isProduction() ? 'prod' : 'non-prod',
      channel,
    }
    if (!userIsAuthenticated) {
      globalEvent.isLoggedIn = false
      globalEvent.Iso = 'GB'
      globalEvent.memberID = null
      globalEvent.balance = null
    }
    pushToDataLayer(globalEvent)
    isInitialGlobalEventSentRef.current = true
    setWaitForPageView(true)
  }, [channel, iso, memberIdentifier, partner?.tag, userBalance, userIsAuthenticated])

  // If the page view event has not been sent, add the event to the buffer
  const pushEventToDataLayer = useCallback<PushEventToDataLayer>(
    (eventName, data) => {
      if (isPageViewEventSent) {
        compareAndPushEventInDataLayer(eventName, data)
      } else {
        setBufferedEvents((oldEventBuffer) => {
          return [...oldEventBuffer, { eventName, data }]
        })
      }
    },
    [isPageViewEventSent]
  )
  // If the page view  event has not been sent, add the event to the buffer
  const onRouteChangeComplete = useCallback(() => {
    if (channel && isInitialGlobalEventSentRef.current) {
      resetAndPushNewPageView({ channel })
      setIsPageViewEventSent(true)
      setWaitForPageView(false)
    }
  }, [channel])

  useEffect(() => {
    if (waitForPageView && isInitialGlobalEventSentRef.current) {
      isInitialEventSentRef.current = true
      onRouteChangeComplete()
    }
  }, [onRouteChangeComplete, waitForPageView])

  // Send the initial new page view event on page refresh (and make sure it's sent only once)
  useEffect(() => {
    if (!isInitialEventSentRef.current) {
      isInitialEventSentRef.current = true
      onRouteChangeComplete()
    }
  }, [onRouteChangeComplete])

  // Send the global datalayer event once
  useEffect(() => {
    if (!isInitialGlobalEventSentRef.current && !isLoadingUser && !isLoadingAccount && channel) {
      if (!userIsAuthenticated) {
        sendGlobalEvent()
      }
      if (userIsAuthenticated && memberIdentifier && userBalance) {
        sendGlobalEvent()
      }
    }
  }, [
    channel,
    isLoadingAccount,
    isLoadingUser,
    memberIdentifier,
    sendGlobalEvent,
    userBalance,
    userIsAuthenticated,
  ])

  const pushBufferedEvents = useCallback(() => {
    bufferedEvents.forEach(({ eventName, data }) => compareAndPushEventInDataLayer(eventName, data))
    setBufferedEvents([])
  }, [bufferedEvents])

  // Once the page view event has been sent, send the buffered events
  useEffect(() => {
    if (bufferedEvents.length > 0 && isPageViewEventSent) {
      pushBufferedEvents()
    }
  }, [bufferedEvents.length, isPageViewEventSent, pushBufferedEvents])

  useEffect(() => {
    const onRouteChangeStart = () => {
      setIsPageViewEventSent(false)
    }

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [onRouteChangeComplete, router.events])

  const context = useMemo(() => ({ pushEventToDataLayer }), [pushEventToDataLayer])

  return <TagManagerContext.Provider value={context}>{children}</TagManagerContext.Provider>
}
