import { helpers } from './base'

export const SearchResultCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    card: {
      padding: {
        base: 'md',
        md: 'lg',
      },
      gap: 'md',
    },
    title: {
      fontSize: {
        base: 'var(--alto-sem-text-heading-xs-font-size)',
        md: 'var(--alto-sem-text-heading-sm-font-size)',
      },
    },
    form: {
      gap: 'md',
    },
    details: {
      rowGap: 'md',
    },
  },
})
