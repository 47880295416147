import { helpers } from './base'

export const Stepper = helpers.defineMultiStyleConfig({
  baseStyle: {
    backgroundLine: {
      backgroundImage: `repeating-linear-gradient( 90deg, transparent 0 4px, var(--alto-sem-color-bg-accent-vibrant-default) 4px 12px )`,
    },
    completedLine: {
      background: 'bg.accent.vibrant.default',
    },
    stepContainer: {
      '&[data-status="default"] > p ': {
        fontWeight: 'normal',
      },
      '&[data-status="selected"] > p ': {
        fontWeight: 'semibold',
      },
    },
    stepText: {
      color: 'fg.accent.secondary',
      fontWeight: 'normal',
    },
  },
})
