import { ChakraTheme } from '@chakra-ui/react'
import { aerLingusTheme } from './aerLingus'
import { vuelingTheme } from './vueling'
import { britishAirwaysTheme } from './britishAirways'
import { aviosTheme } from './avios'

export type ThemeMap = {
  aer_lingus: ChakraTheme
  vueling: ChakraTheme
  british_airways: ChakraTheme
  avios: ChakraTheme
}

export type ChakraThemeMap = Record<keyof ThemeMap, ChakraTheme>

const chakraThemes: ChakraThemeMap = {
  aer_lingus: aerLingusTheme,
  vueling: vuelingTheme,
  british_airways: britishAirwaysTheme,
  avios: aviosTheme,
}

export const chakraThemeMap: ChakraThemeMap = chakraThemes

export const defaultPartner = 'aer_lingus'

export default chakraThemes
