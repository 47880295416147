import { ChakraTheme, ComponentStyleConfig, extendTheme } from '@chakra-ui/react'
import { Card } from '@reward-platform/lift/components/Card/themes/britishAirways'
import { Button } from '@reward-platform/lift/components/ChakraButton/themes/britishAirways'
import { ChakraCollapsibleButton } from '@reward-platform/lift/components/ChakraCollapsibleButton/themes/britishAirways'
import { ChakraDefinitionGroup } from '@reward-platform/lift/components/ChakraDefinitionGroup/themes/britishAirways'
import { Form } from '@reward-platform/lift/components/ChakraField/themes/britishAirways'
import { ListBox } from '@reward-platform/lift/components/ChakraListBox/themes/britishAirways'
import { Checkbox } from '@reward-platform/lift/components/Checkbox/themes/britishAirways'
import { Collapsible } from '@reward-platform/lift/components/Collapsible/themes/britishAirways'
import { CrossSellingCard } from '@reward-platform/lift/components/CrossSellingCard/themes/britishAirways'
import {
  DatePicker,
  DatePickerGlobalTheme,
} from '@reward-platform/lift/components/DatePicker/themes/britishAirways'
import { FieldGroup } from '@reward-platform/lift/components/FieldGroup/themes/britishAirways'
import { Fieldset } from '@reward-platform/lift/components/Fieldset/themes/britishAirways'
import { ImageCard } from '@reward-platform/lift/components/ImageCard/themes/britishAirways'
import { Pagination } from '@reward-platform/lift/components/Pagination/themes/britishAirways'
import { OverlayTip } from '@reward-platform/lift/components/OverlayTip/themes/britishAirways'
import { QuantityPicker } from '@reward-platform/lift/components/QuantityPicker/themes/britishAirways'
import { RadioButton } from '@reward-platform/lift/components/RadioButton/themes/britishAirways'
import { SelectBox } from '@reward-platform/lift/components/SelectBox/themes/britishAirways'
import { StepperCircle } from '@reward-platform/lift/components/Stepper/StepperCircle/themes/britishAirways'
import { Stepper } from '@reward-platform/lift/components/Stepper/themes/britishAirways'
import { Modal } from '@reward-platform/lift/components/Modal/themes/britishAirways'
import { Tabs } from '@reward-platform/lift/components/Tabs/themes/britishAirways'
import { WarningMessage } from '@reward-platform/lift/components/WarningMessage/themes/britishAirways'
import { List } from '@reward-platform/lift/components/List/themes/britishAirways'
import {
  AutoCompleteLocationOption,
  AutoComplete,
} from '@reward-platform/lift/components/AutoComplete/themes/britishAirways'
import { Alert } from '@reward-platform/lift/components/Alert/themes/britishAirways'
import { Tooltip } from '@reward-platform/lift/components/Tooltip/themes/britishAirways'
import { Ratings } from '@reward-platform/lift/components/Ratings/themes/britishAirways'
import { Chip } from '@reward-platform/lift/components/Chip/themes/britishAirways'
import { Carousel } from '@reward-platform/lift/components/Carousel/themes/britishAirways'
import { Spinner } from '@reward-platform/lift/components/Spinner/themes/britishAirways'
import { ImageGallery } from '@reward-platform/lift/components/ImageGallery/themes/britishAirways'
import { TextMarker } from '@reward-platform/lift/components/Map/themes/britishAirways'
import { Switch } from '@reward-platform/lift/components/ChakraSwitch/themes/britishAirways'
import { Drawer } from '@reward-platform/lift/components/ChakraDrawer/themes/britishAirways'
import { NotificationMessage } from '@reward-platform/lift/components/NotificationMessage/themes/britishAirways'
import { RichText } from '@reward-platform/lift/components/ChakraRichText/themes/britishAirways'
import { ChakraPopover } from '@reward-platform/lift/components/ChakraPopover/themes/britishAirways'
import { Combobox } from '@reward-platform/lift/components/ComboBox/themes/britishAirways'
import { TelevisionButton } from '@reward-platform/lift/components/TelevisionButton/themes/britishAirways'
import { RangeDatePicker } from '@reward-platform/lift/components/RangeDatePicker/themes/britishAirways'
import { CancelOrderConfirmationWrapper } from '~/components/order-history/CancelOrderConfirmModal/themes/britishAirways'
import { CustomerReviewList } from '~/components/experiences/details/CustomerReviewList/themes/britishAirways'
import { ItemCardImage } from '~/components/ItemCardImage/themes/britishAirways'
import { ItemCardImageWithLogo } from '~/components/ItemCardImageWithLogo/themes/britishAirways'
import { CarBasketItemCard } from '~/components/orders/BasketItemCard/CarBasketItemCard/themes/britishAirways'
import { ExperienceBasketItemCard } from '~/components/orders/BasketItemCard/ExperienceBasketItemCard/themes/britishAirways'
import { HotelBasketItemCard } from '~/components/orders/BasketItemCard/HotelBasketItemCard/themes/britishAirways'
import { FlightBasketItemCard } from '~/components/orders/BasketItemCard/FlightBasketItemCard/themes/britishAirways'
import { BasketItemCard } from '~/components/orders/BasketItemCard/themes/britishAirways'
import { AncillaryBasketItemDetails } from '~/components/orders/AncillaryBasketItemDetails/themes/britishAirways'
import { BasketItemPaymentOptionsSelect } from '~/components/orders/BasketItemPaymentOptionsSelect/themes/britishAirways'
import { BasketItemSummary } from '~/components/orders/BasketItemSummary/themes/britishAirways'
import { BookingStatus } from '~/components/orders/BookingStatus/themes/britishAirways'
import { Itinerary } from '~/components/orders/Itinerary/themes/britishAirways'
import { AcceptedCards } from '~/components/orders/PaymentForm/AcceptedCards/themes/britishAirways'
import { PaymentFormContainer } from '~/components/orders/PaymentForm/themes/britishAirways'
import { PriceBreakdownSticky } from '~/components/orders/PriceBreakdown/PriceBreakdownSticky/themes/britishAirways'
import { PriceBreakdownSummary } from '~/components/orders/PriceBreakdown/PriceBreakdownSummary/themes/britishAirways'
import { PriceBreakdownSummaryModalButton } from '~/components/orders/PriceBreakdown/PriceBreakdownSummaryModalButton/themes/britishAirways'
import { PriceBreakdownTotalDue } from '~/components/orders/PriceBreakdown/PriceBreakdownTotalDue/theme/britishAirways'
import { PriceBreakdown } from '~/components/orders/PriceBreakdown/themes/britishAirways'
import { PriceBreakdownCollectedAviosAmount } from '~/components/orders/shared/PriceBreakdownCollectedAviosAmount/themes/britishAirways'
import { PriceBreakdownRowItem } from '~/components/orders/shared/PriceBreakdownRowItem/theme/britishAirways'
import { PriceBreakdownCollapsibleContent } from '~/components/orders/shared/PriceBreakdownCollapsibleContent/themes/britishAirways'
import { CarCard } from '~/components/search-and-results/CarCard/themes/britishAirways'
import { FlightJourneyHeader } from '~/components/search-and-results/FlightJourneyHeader/themes/britishAirways'
import { HotelCard } from '~/components/search-and-results/HotelCard/themes/britishAirways'
import { HotelNearbyAttractions } from '~/components/search-and-results/HotelNearbyAttractions/themes/britishAirways'
import { HotelSummary } from '~/components/search-and-results/HotelSummary/themes/britishAirways'
import { IntroSection } from '~/components/search-and-results/IntroSection/themes/britishAirways'
import { LocationDetailsModalWrapper } from '~/components/search-and-results/LocationDetailsModal/themes/britishAirways'
import { NoResults } from '~/components/search-and-results/NoResults/themes/britishAirways'
import { ReplaceCarItemCard } from '~/components/search-and-results/ReplaceCarItemCard/themes/britishAirways'
import { ReplaceHotelItemCard } from '~/components/search-and-results/ReplaceHotelItemCard/themes/britishAirways'
import { ReplaceItemModal } from '~/components/search-and-results/ReplaceItemModal/themes/britishAirways'
import { RoomMoreDetailsModal } from '~/components/search-and-results/RoomMoreDetailsModal/themes/britishAirways'
import { SearchExperienceForm } from '~/components/search-and-results/SearchExperienceForm/themes/britishAirways'
import { SearchFlightForm } from '~/components/search-and-results/SearchFlightForm/themes/britishAirways'
import { EditSearchResultsForm } from '~/components/search-and-results/SearchHotelForm/EditSearchResultsForm/themes/britishAirways'
import { SearchHotelForm } from '~/components/search-and-results/SearchHotelForm/themes/britishAirways'
import { ChildrenAgePicker } from '~/components/shared/ChildrenAgePicker/themes/britishAirways'
import { TravelerRoomPicker } from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/themes/britishAirways'
import { TravelerRoom } from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/TravelerRoom/themes/britishAirways'
import { BackTo } from '~/components/shared/BackTo/themes/britishAirways'
import { CollapsibleForm } from '~/components/shared/CollapsibleForm/themes/britishAirways'
import { ConfirmationModal } from '~/components/shared/ConfirmationModal/themes/britishAirways'
import { RoomRateSelector } from '~/components/search-and-results/RoomRateSelector/themes/britishAirways'
import { TravelerInformationForm } from '~/components/orders/TravelerInformationForm/themes/britishAirways'
import { HotelRooms } from '~/components/search-and-results/HotelRooms/themes/britishAirways'
import { Footer } from '~/components/shared/Footer/themes/britishAirways'
import { GuestRatings } from '~/components/shared/GuestRatings/themes/britishAirways'
import { Header } from '~/components/shared/Header/themes/britishAirways'
import { Menu } from '~/components/shared/HeaderMenu/themes/britishAirways'
import { Layout } from '~/components/shared/Layout/themes/britishAirways'
import { PaymentOptionsContainer } from '~/components/shared/PaymentOptionsContainer/themes/britishAirways'
import { ResultsPageTemplate } from '~/components/search-and-results/ResultsPageTemplate/themes/britishAirways'
import { FlightCard } from '~/components/search-and-results/FlightCard/themes/britishAirways'
import { HotelDetails } from '~/components/hotels/details/themes/britishAirways'
import { ExperienceDetails } from '~/components/experiences/details/details/themes/britishAirways'
import { Filters } from '~/components/shared/Filters/themes/britishAirways'
import { RoomDetailsCard } from '~/components/search-and-results/RoomDetailsCard/themes/britishAirways'
import { Hero } from '~/components/shared/Hero/themes/britishAirways'
import { CarAdditionalProductCard } from '~/components/search-and-results/CarAdditionalProductCard/themes/britishAirways'
import { StatusBadge } from '~/components/order-history/StatusBadge/themes/britishAirways'
import { CancelOrderModal } from '~/components/order-history/CancelOrderModal/themes/britishAirways'
import { Pricing } from '~/components/search-and-results/Pricing/themes/britishAirways'
import { CarPriceBar } from '~/components/search-and-results/CarPriceBar/themes/britishAirways'
import { AmenitiesList } from '~/components/shared/AmenitiesList/themes/britishAirways'
import {
  PopularAmenities,
  PopularAmenitiesList,
} from '~/components/search-and-results/PopularAmenities/themes/britishAirways'
import { ResultsMapView } from '~/components/search-and-results/ResultsMapView/themes/britishAirways'
import { SectionNavigation } from '~/components/shared/SectionNavigation/themes/britishAirways'
import { HotelReviewBreakdown } from '~/components/search-and-results/HotelReviewBreakdown/themes/britishAirways'
import { Progress, ProgressBar } from '~/components/shared/ProgressBar/themes/britishAirways'
import { CancelOrderPaymentForm } from '~/components/order-history/CancelOrderPaymentForm/themes/britishAirways'
import { StaticMap } from '~/components/search-and-results/StaticMap/themes/britishAirways'
import { PageTitle } from '~/components/shared/PageTitle/themes/britishAirways'
import { DetailsPageNav } from '~/components/search-and-results/DetailsPageNav/themes/britishAirways'
import { PolicyPage } from '~/components/shared/PolicyPage/themes/britishAirways'
import { CarDetails } from '~/components/cars/details/themes/britishAirways'
import { Banner } from '~/components/search-and-results/Banner/themes/britishAirways'
import { FlightBasketItemDetails } from '~/components/orders/FlightBasketItemDetails/themes/britishAirways'
import { Title } from '~/components/shared/PageContainer/themes/britishAirways'
import { ExperienceInformation } from '~/components/experiences/details/ExperienceInformation/themes/britishAirways'
import { RouteInfo } from '~/components/experiences/details/RouteInfo/themes/britishAirways'
import { DayList } from '~/components/experiences/details/DayList/themes/britishAirways'
import { PointsOfInterest } from '~/components/experiences/details/PointsOfInterestList/themes/britishAirways'
import { SearchResultCard } from '~/components/experiences/details/SearchResultCard/themes/britishAirways'
import { FeaturesList } from '~/components/experiences/details/FeaturesList/themes/britishAirways'
import { ExperienceTitle } from '~/components/experiences/details/ExperienceTitle/themes/britishAirways'
import { PricingAndSearchCard } from '~/components/experiences/details/PricingAndSearchCard/themes/britishAirways'
import { TravelerQuantityPickerComboBox } from '~/components/shared/TravelerQuantityPickerComboBox/themes/britishAirways'
import { TicketOptions } from '~/components/experiences/details/TicketOptions/themes/britishAirways'
import { FlightItineraryProp } from '~/components/orders/Itinerary/FlightItineraryProp/themes/britishAirways'
import { FlightServiceItineraryProp } from '~/components/orders/Itinerary/FlightServiceItineraryProp/themes/britishAirways'
import { FlightEditSearch } from '~/components/search-and-results/FlightEditSearch/themes/britishAirways'
import { CarItemExtrasPopover } from '~/components/shared/CarItemExtrasPopover/themes/britishAirways'
import { IconValuePair } from '~/components/shared/IconValuePair/theme/britishAirways'
import { BoundDetails } from '~/components/search-and-results/FlightPriceFooter/BoundDetails/themes/britishAirways'

import { SearchFieldSwapButton } from '~/components/search-and-results/SearchFieldSwapButton/themes/britishAirways'
import { FlightBreakdown } from '~/components/search-and-results/FlightPriceFooter/FlightBreakdown/themes/aerLingus'
import { Badge } from '~/components/search-and-results/Badge/themes/britishAirways'
import { FlightSelect } from '~/components/search-and-results/FlightSelect/themes/britishAirways'
import { RoomsBanner } from '~/components/search-and-results/RoomsBanner/themes/britishAirways'
import { BasketTravellersLabel } from '~/components/orders/BasketItemCard/BasketTravellersLabel/themes/britishAirways'
import { StickyBanner } from '@reward-platform/lift/components/StickyBanner/themes/britishAirways'
import { Snackbar } from '@reward-platform/lift/components/Snackbar/themes/britishAirways'
import { HotelAdditionalDetails } from '~/components/orders/AdditionalDetailsForm/HotelAdditionalDetails/themes/britishAirways'
import { WhatToExpect } from '~/components/experiences/details/WhatToExpect/themes/britishAirways'
import { ShareButton } from '~/components/shared/ShareButton/themes/britishAirways'
import { OrderListTable } from '~/components/order-history/OrderListTable/themes/britishAirways'
import { CarHireAdditionalDetails } from '~/components/orders/AdditionalDetailsForm/AccountHolderContactDetails/CarHireAdditionalDetails/themes/britishAirways'
import { ContentBadge } from '@reward-platform/lift/components/ContentBadge/themes/britishAirways'
import { DismissibleModal } from '@reward-platform/lift/components/DismissibleModal/themes/britishAirways'
import { RecentSearches } from '~/components/search-and-results/RecentSearches/themes/britishAirways'
import { PopularDestinations } from '~/components/search-and-results/PopularDestinations/themes/britishAirways'
import { CardBanner } from '@reward-platform/lift/components/CardBanner/themes/britishAirways'
import { MeetingPoints } from '~/components/experiences/details/MeetingPoints/themes/britishAirways'
import { Dropdown } from '~/components/shared/Dropdown/themes/britishAirways'
import { UpgradeBasketItem } from '~/components/orders/UpgradeBasketItem/themes/britishAirways'
import { ChatWidgetMock } from '~/components/shared/ChatWidgetMock/themes/britishAirways'
import { baseTheme } from './base'

const FormError: ComponentStyleConfig = {
  baseStyle: {
    text: {
      color: 'brand.alertPink',
      fontWeight: 'normal',
    },
  },
}

const primaryBlue = 'rgb(var(--chakra-colors-rgb-blue))'
const midnightBlue = 'rgb(var(--chakra-colors-rgb-midnightBlue))'
const outlineColor = primaryBlue

const focusOutline = {
  outlineColor,
  outlineWidth: 1,
  borderRadius: 'sm',
  outlineStyle: 'solid',
}

const Link: ComponentStyleConfig = {
  baseStyle: {
    _focusVisible: {
      boxShadow: 'none',
      ...focusOutline,
    },
  },
}

export const britishAirwaysTheme = extendTheme(
  {
    styles: {
      global: {
        'html, body, div#__next': {
          color: 'brand.primary.midnightBlue',
        },
        body: {
          input: {
            _placeholder: {
              color: 'brand.baseGray.25',
            },
          },
          fontWeight: 'light',
        },
        'p, h1, h2, h3, h4, h5, h6': {
          fontWeight: 'light',
        },
        a: {
          color: 'brand.highlight.blue',
        },
        '*:focus-visible': focusOutline,
        ...DatePickerGlobalTheme,
      },
    },
    shadows: {
      brand: {
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        offsetShadow: ' 0 4px 4px -2px rgb(0 0 0 / 40%)',
      },
    },
    fonts: {
      heading: 'Mylius Modern',
      body: 'Open Sans',
    },
    fontSizes: {
      '4xl-5': '2.75rem',
    },
    space: {
      '4-5': '1.125rem',
    },
    borders: {
      '10px': '10px solid',
    },
    colors: {
      rgb: {
        blue: '46, 92, 153',
        backgroundBlue500: '234, 239, 245',
        alphaBlue: '244, 247, 250',
        red: '206, 33, 15',
        midnightBlue: '2, 27, 65',
        gray5: '250, 250, 250',
        gray10: '236, 236, 237',
        gray25: '216, 217, 220',
        gray50: '182, 183, 189',
        gray75: '204, 209, 217',
        gray100: '113, 115, 127',
        highlightBlue: '38, 113, 211',
        highlightRed: '232, 17, 28',
        white: ' 255, 255, 255',
        cloudWhite: '249, 249, 249',
        black: '0, 0, 0',
        alertPink: '204, 0, 102',
        confirmationGreen: '0, 128, 88',
        baCoolGrey4: '187, 188, 188',
        coolgrey2: '208, 208, 206',
        green: '0, 128, 88',
        red2: '183, 62, 62',
      },
      brand: {
        primary: {
          blue: 'rgb(var(--chakra-colors-rgb-blue))',
          alphaBlue: 'rgb(var(--chakra-colors-rgb-alphaBlue))',
          red: 'rgb(var(--chakra-colors-rgb-red))',
          midnightBlue,
        },
        baseGray: {
          5: 'rgb(var(--chakra-colors-rgb-gray5))',
          10: 'rgb(var(--chakra-colors-rgb-gray10))',
          25: 'rgb(var(--chakra-colors-rgb-gray25))',
          50: 'rgb(var(--chakra-colors-rgb-gray50))',
          75: 'rgb(var(--chakra-colors-rgb-gray75))',
          100: 'rgb(var(--chakra-colors-rgb-gray100))',
        },
        highlight: {
          blue: 'rgb(var(--chakra-colors-rgb-highlightBlue))',
          red: 'rgb(var(--chakra-colors-rgb-highlightRed))',
        },
        alpha: {
          blue: 'rgba(var(--chakra-colors-rgb-blue), 0.1)',
          gray10: 'rgba(var(--chakra-colors-rgb-gray10), 0.5)',
        },
        cloudWhite: 'rgb(var(--chakra-colors-rgb-cloudWhite))',
        white: 'rgb(var(--chakra-colors-rgb-white))',
        black: 'rgb(var(--chakra-colors-rgb-black))',
        alertPink: 'rgb(var(--chakra-colors-rgb-alertPink))',
        confirmationGreen: 'rgb(var(--chakra-colors-rgb-confirmationGreen))',
        coolgrey4: 'rgb(var(--chakra-colors-rgb-baCoolGrey4))',
        coolgrey2: 'rgb(var(--chakra-colors-rgb-coolgrey2))',
        green: 'rgb(var(--chakra-colors-rgb-green))',
        red2: 'rgb(var(--chakra-colors-rgb-red2))',
        icon: midnightBlue,
        inputIcon: midnightBlue,
        disabledIcon: 'rgb(var(--chakra-colors-rgb-gray25))',
        focusOutline: outlineColor,
        backgroundBlue500: 'rgb(var(--chakra-colors-rgb-backgroundBlue500))',
      },
      error: {
        primary: '#CC0066',
      },
    },
    components: {
      Link,
      FormError,
      Footer,
      HotelNearbyAttractions,
      NoResults,
      Header,
      Menu,
      HotelSummary,
      GuestRatings,
      QuantityPicker,
      Button,
      PriceBreakdownRowItem,
      PriceBreakdownCollapsibleContent,
      PriceBreakdownSummaryModalButton,
      PriceBreakdownSticky,
      PriceBreakdownTotalDue,
      PriceBreakdown,
      PriceBreakdownSummary,
      Card,
      ChakraCollapsibleButton,
      CrossSellingCard,
      IntroSection,
      ImageCard,
      BackTo,
      HotelCard,
      Tabs,
      Stepper,
      StepperCircle,
      PaymentOptionsContainer,
      BasketItemCard,
      AncillaryBasketItemDetails,
      CarBasketItemCard,
      BasketItemSummary,
      BasketItemPaymentOptionsSelect,
      RadioButton,
      WarningMessage,
      List,
      ListBox,
      Itinerary,
      Collapsible,
      ReplaceItemModal,
      ReplaceHotelItemCard,
      ReplaceCarItemCard,
      ChildrenAgePicker,
      TravelerRoomPicker,
      TravelerRoom,
      CustomerReviewList,
      HotelBasketItemCard,
      ItemCardImage,
      ExperienceBasketItemCard,
      CollapsibleForm,
      FlightJourneyHeader,
      SelectBox,
      SearchHotelForm,
      EditSearchResultsForm,
      SearchExperienceForm,
      SearchFlightForm,
      CarCard,
      ItemCardImageWithLogo,
      FlightCard,
      BookingStatus,
      Fieldset,
      Form,
      Checkbox,
      RoomMoreDetailsModal,
      AcceptedCards,
      PaymentFormContainer,
      FieldGroup,
      ChakraDefinitionGroup,
      PriceBreakdownCollectedAviosAmount,
      RoomRateSelector,
      DatePicker,
      TravelerInformationForm,
      AutoCompleteLocationOption,
      ResultsPageTemplate,
      AutoComplete,
      Hero,
      HotelRooms,
      CarAdditionalProductCard,
      Layout,
      RoomDetailsCard,
      OrderListTable,
      StatusBadge,
      Alert,
      Tooltip,
      Ratings,
      PopularAmenities,
      Pricing,
      Chip,
      CarPriceBar,
      HotelDetails,
      ExperienceDetails,
      Filters,
      Carousel,
      Modal,
      AmenitiesList,
      PopularAmenitiesList,
      Spinner,
      ResultsMapView,
      TextMarker,
      Switch,
      Pagination,
      CancelOrderModal,
      SectionNavigation,
      HotelReviewBreakdown,
      Progress,
      ProgressBar,
      NotificationMessage,
      ImageGallery,
      CancelOrderPaymentForm,
      PageTitle,
      DetailsPageNav,
      ConfirmationModal,
      ChakraPopover,
      StaticMap,
      Drawer,
      CarDetails,
      CancelOrderConfirmationWrapper,
      FlightBasketItemCard,
      PolicyPage,
      RichText,
      Banner,
      FlightBasketItemDetails,
      Title,
      ExperienceInformation,
      FlightItineraryProp,
      FlightServiceItineraryProp,
      RouteInfo,
      DayList,
      PointsOfInterest,
      SearchResultCard,
      FeaturesList,
      ExperienceTitle,
      PricingAndSearchCard,
      TravelerQuantityPickerComboBox,
      Combobox,
      TicketOptions,
      TelevisionButton,
      CarItemExtrasPopover,
      RangeDatePicker,
      FlightEditSearch,
      IconValuePair,
      BoundDetails,
      LocationDetailsModalWrapper,
      SearchFieldSwapButton,
      FlightBreakdown,
      CardBanner,
      FlightSelect,
      Badge,
      OverlayTip,
      RoomsBanner,
      BasketTravellersLabel,
      StickyBanner,
      Snackbar,
      HotelAdditionalDetails,
      WhatToExpect,
      ShareButton,
      CarHireAdditionalDetails,
      ContentBadge,
      DismissibleModal,
      RecentSearches,
      PopularDestinations,
      MeetingPoints,
      Dropdown,
      UpgradeBasketItem,
      ChatWidgetMock,
    },
  },
  baseTheme
) as ChakraTheme
