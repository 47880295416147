import { helpers } from './base'

export const Modal = helpers.defineMultiStyleConfig({
  baseStyle: {
    dialog: {
      bg: 'bg.base',
      h2: {
        textAlign: 'left',
      },
    },
    overlay: {
      bgColor: 'var(--alto-sem-color-overlay-scrim)',
    },
  },
})
