import { helpers } from './base'

export const CardBanner = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      borderRadius: 'xs',
      color: 'fg.accent.onVibrant',
    },
    overlayContainer: {
      borderRadius: 'xs',
    },
  }),
})
