import { Clusters } from '@reward-platform/contentful'
import {
  ContentfulCrossSelling,
  ContentfulCrossSellingSchema,
  PartnerTag,
  ContentfulPromotionComponents,
  ContentfulPromotionComponentsSchema,
  ContentfulSearchPage,
  ContentfulSearchPageSchema,
  ContentfulResultsBanner,
  ContentfulResultsBannerSchema,
  ContentfulProductType,
  ContentfulEntriesRequestParamsSchema,
  CancellationPolicies,
  CancellationPoliciesSchema,
  ContentfulAviosOnlyPromotionContent,
  ContentfulAviosOnlyPromotionContentSchema,
  ContentfulDisruptionMessage,
  ContentfulTermsAndConditions,
  ContentfulStaticPageContent,
  ContentfulComponentImage,
} from '@reward-platform/shared-schemas'
import { bffClient } from './clients/bffClient'
import { createServiceSchemaValidator } from './utils'
import { PlatformError } from '../utils/errors'

/**
 * Get Search Page data
 * @param preview Whether to fetch the latest preview (unpublished) content
 * @param partner fetch the Search Page of a particular partner
 * @param locale fetch the Search Page of a particular locale
 * @returns a searchPageCollection with object organised by AncillaryType
 */

export const getSearchPageContent = async (
  partner: string,
  locale?: string,
  preview?: boolean
): Promise<ContentfulSearchPage> => {
  const validateParams = createServiceSchemaValidator(ContentfulEntriesRequestParamsSchema)
  const params = validateParams({
    partner,
    ...(locale ? { locale } : { locale: 'en-GB' }),
    ...(preview ? { preview } : {}),
  })

  const { data } = await bffClient.get('/contentful/search-page', { params })

  const validateContentfulData = createServiceSchemaValidator(ContentfulSearchPageSchema)
  return validateContentfulData(data)
}

/**
 * Request Message Cluster(s) for a specific locale from Contentful
 * @param {string[]} clusters Keys used to identify the clusters
 * @param {string} locale Locale to retrieve the messages in
 * @param {boolean} preview Whether to retrieve the unpublished results from Contentful
 * @returns {Promise<Clusters>} An asynchronous response containing the clusters with their messages
 */

export const getMessageClusters = async (
  partner: PartnerTag,
  clusters: string[],
  locale: string,
  preview?: boolean
): Promise<Clusters> => {
  if (!clusters?.length || !locale) {
    throw new Error('Missing message clusters parameter')
  }

  const params = {
    partner,
    clusters: clusters.toString(),
    locale,
    ...(preview ? { preview } : {}),
  }

  const { data } = await bffClient.get('/contentful/cluster', { params })

  return data as Clusters
}

/**
 * Get Terms and Conditions content type data
 * @param preview Whether to fetch the latest preview (unpublished) content
 * @param locale fetch the terms and conditions of a particular locale
 * @param identifier fetch based on a specific identifier
 * @returns Terms and Condition collection
 */

export const getTermsAndConditions = async (
  partner: string,
  identifier: string,
  locale?: string,
  preview?: boolean
): Promise<ContentfulTermsAndConditions> => {
  if (!partner) {
    throw new PlatformError('Missing search page content parameter')
  }

  const params = {
    partner,
    ...(identifier ? { identifier } : {}),
    ...(locale ? { locale } : { locale: 'en-US' }),
    ...(preview ? { preview } : {}),
  }

  const { data } = await bffClient.get('/contentful/termsAndConditions', { params })
  return data
}

/**
 * Get Disruption Message data
 * @param preview Whether to fetch the latest preview (unpublished) content
 * @param locale fetch the Disruption Messages of a particular locale
 * @returns a DisruptionMessageCollection
 */

export const getDisruptionMessages = async (
  partner: string,
  locale?: string,
  preview?: boolean
): Promise<Array<ContentfulDisruptionMessage>> => {
  if (!partner) {
    throw new PlatformError('Missing disruption message content parameter')
  }

  const params = {
    partner,
    ...(locale ? { locale } : { locale: 'en-US' }),
    ...(preview ? { preview } : {}),
  }

  const { data } = await bffClient.get('/contentful/disruption', { params })
  return data
}

/**
 * Get Static Page content (e.g. T&Cs and Cookie Policy)
 * @param preview Whether to fetch the latest preview (unpublished) content
 * @param locale fetch the Static Page of a particular locale
 * @returns a static page collection
 */

export const getStaticPageContent = async (
  partner: string,
  identifier?: string,
  locale?: string,
  preview?: boolean
): Promise<ContentfulStaticPageContent> => {
  if (!partner) {
    throw new PlatformError('Missing static page content parameter')
  }

  const params = {
    partner,
    ...(identifier ? { identifier } : {}),
    ...(locale ? { locale } : { locale: 'en-US' }),
    ...(preview ? { preview } : {}),
  }

  const { data } = await bffClient.get('/contentful/static-page-content', { params })
  return data
}

/**
 * Get Image content
 * @param preview Whether to fetch the latest preview (unpublished) content
 * @param locale fetch the image of a particular locale
 * @returns a static single image
 */

export const getAssetContent = async (
  partner: PartnerTag,
  identifier?: string,
  locale?: string,
  preview?: boolean
): Promise<ContentfulComponentImage> => {
  if (!partner) {
    throw new PlatformError('Missing image content parameter')
  }

  const params = {
    partner,
    ...(identifier ? { identifier } : {}),
    ...(locale ? { locale } : { locale: 'en-US' }),
    ...(preview ? { preview } : {}),
  }

  const { data } = await bffClient.get(`/contentful/image-content`, { params })
  return data
}

/**
 * Get Details Page content (e.g. CarHire Details)
 * @param preview Whether to fetch the latest preview (unpublished) content
 * @param locale fetch the Static Page of a particular locale
 * @returns a static page collection
 */

export const getDetailsPageContent = async (
  partner: string,
  identifier?: string,
  locale?: string,
  preview?: boolean
): Promise<ContentfulStaticPageContent> => {
  if (!partner) {
    throw new PlatformError('Missing details page content parameter')
  }

  const params = {
    partner,
    ...(identifier ? { identifier } : {}),
    ...(locale ? { locale } : { locale: 'en-US' }),
    ...(preview ? { preview } : {}),
  }

  const { data } = await bffClient.get('/contentful/details-page-content', { params })
  return data
}

export const getCrossSelling = async (
  partner: string,
  identifier?: string,
  locale?: string,
  preview?: boolean
): Promise<ContentfulCrossSelling> => {
  if (!partner) {
    throw new PlatformError('Missing cross selling content parameter')
  }

  const params = {
    partner,
    ...(identifier ? { identifier } : {}),
    ...(locale ? { locale } : { locale: 'en-US' }),
    ...(preview ? { preview } : {}),
  }

  const { data } = await bffClient.get(`/contentful/cross-selling`, { params })

  const validateContentfulData = createServiceSchemaValidator(ContentfulCrossSellingSchema)
  return validateContentfulData(data)
}

export const getPromotionsContent = async (
  partner: string,
  locale?: string,
  preview?: boolean
): Promise<ContentfulPromotionComponents[]> => {
  const params = {
    partner,
    ...(locale ? { locale } : { locale: 'en-GB' }),
    ...(preview ? { preview } : {}),
  }
  const { data } = await bffClient.get('/contentful/promotion-content', { params })

  const validateContentfulData = createServiceSchemaValidator(
    ContentfulPromotionComponentsSchema.array()
  )
  return validateContentfulData(data)
}

export const getResultsBannerContent = async (
  partner: string,
  locale?: string,
  preview?: boolean,
  product?: ContentfulProductType
): Promise<ContentfulResultsBanner[]> => {
  const params = { partner, locale, product, ...(preview ? { preview } : {}) }

  const { data } = await bffClient.get(`/contentful/results-banner-content`, { params })
  const validateContentfulData = createServiceSchemaValidator(ContentfulResultsBannerSchema.array())
  return validateContentfulData(data)
}

export const getAviosOnlyPromotion = async (
  partner: string,
  locale?: string,
  preview?: boolean
): Promise<ContentfulAviosOnlyPromotionContent> => {
  const params = {
    partner,
    ...(locale ? { locale } : { locale: 'en-GB' }),
    ...(preview ? { preview } : {}),
  }
  const { data } = await bffClient.get('/contentful/avios-only-promotion', { params })

  const validateContentfulData = createServiceSchemaValidator(
    ContentfulAviosOnlyPromotionContentSchema
  )
  return validateContentfulData(data)
}

export const getCancellationPolicies = async (
  locale?: string,
  preview?: boolean
): Promise<CancellationPolicies> => {
  const params = {
    ...(locale ? { locale } : { locale: 'en-GB' }),
    ...(preview ? { preview } : {}),
  }
  const { data } = await bffClient.get('/contentful/cancellation-policies', { params })

  const validateContentfulData = createServiceSchemaValidator(CancellationPoliciesSchema)
  return validateContentfulData(data)
}
