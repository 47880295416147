/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const borderRadiusCircle = { borderRadius: 'var(--alto-sem-radius-circle) !important' }
const borderRadiusStart = {
  borderTopLeftRadius: 'var(--alto-sem-radius-circle)',
  borderBottomLeftRadius: 'var(--alto-sem-radius-circle)',
}
const borderRadiusEnd = {
  borderTopRightRadius: 'var(--alto-sem-radius-circle)',
  borderBottomRightRadius: 'var(--alto-sem-radius-circle)',
}

const colors = {
  bgVibrant: 'var(--alto-sem-color-bg-accent-vibrant-default)',
  bgVibrantHover: 'var(--alto-sem-color-bg-accent-vibrant-hover)',
  bgVibrantActive: 'var(--alto-sem-color-bg-accent-vibrant-active)',
  fgOnVibrant: 'var(--alto-sem-color-fg-accent-on-vibrant)',
  bgSubtle: 'var(--alto-sem-color-bg-accent-subtle-default)',
  bgSubtleHover: 'var(--alto-sem-color-bg-accent-subtle-hover)',
  bgSubtleActive: 'var(--alto-sem-color-bg-accent-subtle-active)',
  fgOnSubtle: 'var(--alto-sem-color-fg-accent-on-subtle)',
  borderAccent: 'var(--alto-sem-color-border-accent)',
  fgAccentPrimary: 'var(--alto-sem-color-fg-accent-primary)',
  fgAccentSecondary: 'var(--alto-sem-color-fg-accent-secondary)',
}

const selectedDateCircle = {
  position: 'relative',
  backgroundColor: colors.bgVibrant,
  color: colors.fgOnVibrant,
  ...borderRadiusCircle,
}

const hoverDateCircle = {
  position: 'relative',
  backgroundColor: `${colors.bgVibrantHover} !important`,
  color: colors.fgOnVibrant,
  ...borderRadiusCircle,
}

const focusDateCircle = {
  position: 'relative',
  backgroundColor: colors.bgVibrantActive,
  ...borderRadiusCircle,
  outlineStyle: 'solid',
  outlineColor: colors.borderAccent,
  outlineOffset: 1,
}

const selectedRangeBackground = {
  content: '""',
  display: 'block',
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: '0',
  top: '0',
  zIndex: '-1',
  color: colors.fgOnSubtle,
  backgroundColor: colors.bgSubtle,
}

export const DatePicker = helpers.defineMultiStyleConfig({
  baseStyle: {},
})

export const DatePickerGlobalTheme = {
  '.react-datepicker': {
    boxShadow: 'unset',
    borderRadius: 'sm',
  },
  '.react-datepicker__current-month, .react-datepicker-time__header': {
    fontSize: '2xl',
    fontWeight: 'light',
  },

  '.react-datepicker__navigation': {
    ':hover, :focus-visible, :focus': {
      boxShadow: 'unset !important',
      border: 'none',
      color: colors.fgAccentPrimary,
      '*': {
        color: colors.fgAccentPrimary,
      },
    },
    ':focus-visible, :focus': {
      border: 'solid',
      borderWidth: 1,
      borderRadius: 'sm',
      borderColor: colors.borderAccent,
    },
  },
  '.react-datepicker__navigation-icon': {
    _before: {
      borderColor: colors.fgAccentPrimary,
      height: '16px',
      width: '11px',
      top: 2.5,
      borderRadius: '1px 0 1px 0',
    },
  },
  '.react-datepicker__navigation--previous': {
    top: {
      base: '15px',
      md: '20px',
    },
  },

  '.react-datepicker__navigation--next': {
    top: '20px',
  },

  '.react-datepicker__input-container': {
    '.date-picker-from__label, .date-picker-to__label': {
      color: 'brand.baseGray.100',
      fontSize: 'xs',
    },
    input: {
      fontSize: 'md',
      // borderColor: 'brand.coolgrey4',
      borderRadius: 'sm',

      _focus: {
        boxShadow: `0 0 2px var(--chakra-colors-brand-primary-blue)`,
        borderColor: colors.borderAccent,

        '& + .date-picker-to__label, + .date-picker-from__label': {
          color: colors.fgAccentPrimary,
        },
      },
    },
  },
  '.react-datepicker__year-read-view--down-arrow': {
    top: '2px',
    borderColor: colors.borderAccent,
  },
  '.react-datepicker__year-dropdown': {
    top: '64px',
  },
  '.react-datepicker__navigation--years-previous': {
    _before: {
      borderColor: colors.borderAccent,
    },
  },
  '.react-datepicker__navigation--years-upcoming': {
    _before: {
      borderColor: colors.borderAccent,
    },
  },
  '.react-datepicker__day--selected': {
    ...selectedDateCircle,
    backgroundColor: colors.bgVibrant,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__day--in-range': {
    position: 'relative',
    color: colors.fgOnSubtle,
    '&::after': selectedRangeBackground,
  },
  '.react-datepicker__day--in-selecting-range': {
    position: 'relative',
    _hover: hoverDateCircle,
    '&::after': selectedRangeBackground,
  },
  '.react-datepicker__week': {
    zIndex: 1,
    position: 'relative',
  },
  '.react-datepicker__month-text--selected': {
    backgroundColor: colors.bgVibrant,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__month-text--in-selecting-range': {
    backgroundColor: colors.bgVibrant,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__month-text--in-range': {
    backgroundColor: colors.bgVibrant,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__quarter-text--selected': {
    backgroundColor: colors.bgVibrant,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__quarter-text--in-selecting-range': {
    backgroundColor: colors.bgVibrant,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__quarter-text--in-range': {
    backgroundColor: colors.bgVibrant,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__year-text--selected': {
    backgroundColor: colors.bgVibrant,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__day--keyboard-selected': {
    ...selectedDateCircle,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__day--range-start, .react-datepicker__day--selecting-range-start': {
    ...selectedDateCircle,
    _hover: hoverDateCircle,
    '&::after': {
      ...selectedRangeBackground,
      ...borderRadiusStart,
    },
  },
  '.react-datepicker__day--range-end': {
    ...selectedDateCircle,
    _hover: {
      backgroundColor: colors.bgVibrant,
      ...borderRadiusCircle,
    },
    '&::after': {
      ...selectedRangeBackground,
      ...borderRadiusEnd,
    },
  },
  '.react-datepicker__day': {
    _hover: hoverDateCircle,
    _focusVisible: focusDateCircle,
  },

  // Make border radius to first and last elements

  // First
  '.react-datepicker__day--in-range:first-of-type, .react-datepicker__day--in-selecting-range:first-of-type, .react-datepicker__day--in-range.react-datepicker__day--001, .react-datepicker__day--in-selecting-range.react-datepicker__day--001':
    {
      '&::after': { ...borderRadiusStart },
      _hover: { '&::after': { ...borderRadiusStart } },
    },

  // Last
  '.react-datepicker__day--in-range:last-of-type, .react-datepicker__day--in-selecting-range:last-of-type, .react-datepicker__day--in-range.react-datepicker__day--031, .react-datepicker__day--in-selecting-range. react-datepicker__day--031':
    {
      '&::after': { ...borderRadiusEnd },
      _hover: { '&::after': { ...borderRadiusEnd } },
    },

  '.react-datepicker__week:not(:has(.react-datepicker__day--031))': {
    '.react-datepicker__day--in-range.react-datepicker__day--030, .react-datepicker__day--in-selecting-range.react-datepicker__day--030':
      {
        '&::after': { ...borderRadiusEnd },
        _hover: { '&::after': { ...borderRadiusEnd } },
      },
  },
}
