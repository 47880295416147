import { helpers } from './base'

const bgLayer2 = 'bg.layer2.default'

export const Tabs = helpers.defineMultiStyleConfig({
  baseStyle: {
    tabListButton: {
      borderRadius: 'var(--chakra-radii-xs) var(--chakra-radii-xs) 0 0',
      fontWeight: 'medium',
      textTransform: 'none',
      height: 14,
      p: 'var(--alto-sem-space-sm) var(--alto-sem-space-lg) var(--alto-sem-space-sm) var(--alto-sem-space-lg)',
      border: 'solid 1px var(--chakra-colors-border-accent)',
      bgColor: 'bg.accent.vibrant.default',
      color: 'fg.accent.onVibrant',

      marginRight: 'var(--alto-sem-space-5xs)',

      _focusVisible: {
        outlineStyle: 'solid',
        outlineColor: 'border.accent',
        outlineOffset: '2px',
        outlineWidth: '2px',
        position: 'relative',
        marginTop: 2,
        border: 'none',
      },

      _activeLink: {
        border: 'none',
        bgColor: bgLayer2,
        color: 'fg.accent.onSubtle',

        _focusVisible: {
          outlineStyle: 'solid',
          outlineWidth: 2,
          outlineColor: 'border.accent',
        },
      },
    },
    tabWrap: {
      _before: { bgColor: bgLayer2 },
      marginLeft: {
        base: '0',
        md: 2,
      },
      marginRight: {
        base: '0',
        md: 2,
      },
    },
    tabContent: {
      backgroundColor: bgLayer2,
      borderRadius: {
        base: '0',
        md: '0 var(--chakra-radii-xs) var(--chakra-radii-xs) var(--chakra-radii-xs)',
      },
    },
  },
})
