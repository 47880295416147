/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const RecentSearches = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      h4: {
        padding: 0,
        px: {
          base: 0,
          lg: 'lg',
        },
        py: 'lg',
        fontSize: 'lg',
        fontWeight: 'var(--alto-sem-text-body-bold-font-weight)',
      },
    },
    item: {
      '&.chakra-button': {
        borderBottom: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-tertiary)',
        borderTop: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-tertiary)',
        margin: {
          base: 0,
          lg: '0 var(--alto-sem-space-sm)',
        },
        padding: {
          base: 'var(--alto-sem-space-xs) 0',
          lg: 'var(--alto-sem-space-xs) var(--alto-sem-space-lg)',
        },
        _hover: {
          '*': {
            color: 'fg.accent.primary',
          },
        },
        _focus: {
          borderRadius: 0,
          borderWidth: 'var(--alto-sem-border-width-sm)',
          borderColor: 'border.tertiary',
        },
        _active: {
          borderColor: 'border.tertiary',
        },
        '&:first-of-type': {
          borderTop: {
            base: 'none',
            lg: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-tertiary)',
          },
        },
      },
      svg: {
        color: 'fg.accent.secondary',
        marginRight: {
          base: '2xs',
          lg: 'sm',
        },
      },
      p: {
        color: 'fg.accent.secondary',
      },
      'p:first-of-type': {
        fontWeight: 'semibold',
      },
      'p:nth-of-type(2)': {
        fontWeight: 'light',
      },
      'p:nth-of-type(3)': {
        fontWeight: 'normal',
        marginTop: 'xs',
      },
      _hover: {
        bgColor: 'bg.layer1.default',
      },
    },
  },
})
