import { NextPageContext } from 'next'
import { GenericClientError } from './GenericClientError'
import { ERROR_CODES } from './clientErrors'

const BUSINESS_MESSAGE = 'Fatal error has occurred, please refresh the application.'

type NextError = NonNullable<NextPageContext['err']>

export class NextPageError extends GenericClientError {
  constructor(error: NextError) {
    super(ERROR_CODES.NEXT_PAGE, error)

    this.developerMessage = error.message
    this.businessMessage = BUSINESS_MESSAGE
    this.fatal = true
  }
}
