import merge from 'lodash/merge'
import { ComponentStyleConfig } from '@chakra-ui/react'
import { SkeletonCard as base } from './base'

export const SkeletonCard: ComponentStyleConfig = merge({}, base, {
  baseStyle: {
    borderRadius: 'xs',
    overflow: 'hidden',
    boxShadow: 'none',
    background: 'bg.layer2.default',
  },
})
