/* eslint-disable dot-notation */
import { z } from 'zod'
import {
  CALLBACK_PATH,
  GENERAL_ERROR_PATH,
  HOTEL_DETAILS_PATH,
  HOTEL_RESULTS_PATH,
  LANDING_PAGE_PATH,
  LOGIN_ERROR_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  PAYMENT_COMPLETE_PATH,
  SHARE_URL_PATH,
  TERMS_AND_CONDITIONS_PATH,
} from './unauthenticatedPages'
import { Auth0PartnerCode, partnerAuthConfigs } from './partnerAuthConfigs'

const COMMA_SEPARATOR = ','
const SPACE_SEPARATOR = ' '
export const isString = (value: unknown): value is string => typeof value === 'string'

const Auth0PartnerProviderOptionsSchema = z.object({
  domain: z.string(),
  clientId: z.string(),
  authorizationParams: z.object({
    audience: z.string().min(1),
    redirect_uri: z.string(),
    scope: z.preprocess(
      (value: unknown) =>
        isString(value) ? value.split(COMMA_SEPARATOR).join(SPACE_SEPARATOR) : value,
      z.string()
    ),
  }),
  cacheLocation: z.union([z.literal('memory'), z.literal('localstorage')]).default('memory'),
})
type Auth0PartnerProviderOptions = z.infer<typeof Auth0PartnerProviderOptionsSchema>

export const getCallbackUrl = () =>
  typeof window !== 'undefined' ? `${window.location.protocol}//${window.location.host}` : ''

export const parseAuth0Config = (
  partnerCode: Auth0PartnerCode,
  basePath?: string
): Auth0PartnerProviderOptions => {
  try {
    const callbackPath = basePath ? `${basePath}${CALLBACK_PATH}` : CALLBACK_PATH
    const redirectUri = `${getCallbackUrl()}${callbackPath}`

    const basePartnerConfig = partnerAuthConfigs[partnerCode]
    const { scope, audience, ...restOfBasePartnerConfig } = basePartnerConfig
    const rawConfig = {
      ...restOfBasePartnerConfig,
      authorizationParams: {
        scope,
        audience,
        redirect_uri: redirectUri,
        prompt: 'none',
      },
    }

    return Auth0PartnerProviderOptionsSchema.parse(rawConfig)
  } catch (err) {
    throw new Error(`Auth0 Config is not setup correctly for partner "${partnerCode}"`)
  }
}

export const isUnauthenticatedPage = (
  pathname?: string,
  tabQuery?: string | string[],
  isUnauthorizedEnabled?: boolean
) => {
  if (!pathname) {
    return false
  }

  if (
    isUnauthorizedEnabled &&
    pathname === LANDING_PAGE_PATH &&
    (tabQuery === 'hotels' || tabQuery === undefined)
  ) {
    return true
  }

  switch (pathname) {
    case LOGIN_PATH:
    case LOGOUT_PATH:
    case LOGIN_ERROR_PATH:
    case GENERAL_ERROR_PATH:
    case CALLBACK_PATH:
    case PAYMENT_COMPLETE_PATH:
    case TERMS_AND_CONDITIONS_PATH:
      return true
    case HOTEL_DETAILS_PATH:
    case HOTEL_RESULTS_PATH:
    case SHARE_URL_PATH:
      if (isUnauthorizedEnabled) {
        return true
      }
      return false
    default:
      return false
  }
}
