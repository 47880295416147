/* eslint-disable sonarjs/no-duplicate-string */
import { helper } from './base'

export const UpgradeBasketItem = helper.defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderBottomRadius: 'xs',
      bg: 'bg.accent.subtle.default',
      'label > p': {
        width: 'full',
        paddingRight: '2xs',
        fontSize: 'xs',
      },
      'div:nth-child(2) > div > div': {
        '> input': {
          borderRadius: 'xs',
        },
      },
      ul: {
        '> li > div': {
          '> div': {
            '> span': {
              '> p': {
                fontSize: 'xs',
              },
            },
          },
        },
      },
    },
    icon: {
      color: 'fg.accent.onSubtle',
    },
    iconCircle: {
      'path:first-child': {
        fill: 'fg.accent.onSubtle',
      },
      'path:nth-child(2)': {
        fill: 'bg.accent.onVibrant.default',
      },
    },
    text: {
      fontSize: 'md',
      fontWeight: 'semibold',
      color: 'fg.accent.onSubtle',
    },
  },
  variants: {
    upgrade: {
      button: {
        backgroundColor: 'bg.accent.vibrant.default',
      },
    },
    confirmUpgrade: {
      button: {
        backgroundColor: 'bg.accent.vibrant.default',
      },
    },
  },
})
