import React, { useContext, createContext, PropsWithChildren } from 'react'
import { PlatformError } from '~/utils/errors'
import { Partner } from '~/services/partnerService'

const PartnerContext = createContext<Partner | undefined>(undefined)

export const usePartner = () => {
  const partner = useContext(PartnerContext)

  if (!partner) {
    throw new PlatformError('usePartner must be used within PartnerProvider')
  }

  return partner
}

export const PartnerProvider = ({ children, partner }: PropsWithChildren<{ partner: Partner }>) => (
  <PartnerContext.Provider value={partner}>{children}</PartnerContext.Provider>
)
