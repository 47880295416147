import { helper } from './base'

export const PriceBreakdown = helper.defineMultiStyleConfig({
  baseStyle: {
    styledPriceBreakdownSummaryWrapper: {
      borderRadius: 'xs',
      bg: 'bg.base',
      boxShadow: '0px 2px 4px -2px #0000001A, 0px 4px 6px -1px #0000001A',
      h4: {
        color: 'fg.accent.secondary',
        fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
        fontWeight: 700,
      },
      hr: {
        borderColor: 'border.primary.default',
        opacity: '0.2',
      },
    },
  },
  variants: {
    noBg: {
      styledPriceBreakdownSummaryWrapper: {
        bg: 'none',
        boxShadow: 'none',
      },
    },
  },
})
