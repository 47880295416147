import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'card',
  'stack',
  'image',
  'content',
  'heading',
  'description',
  'duration',
  'pricingContainer',
])

export const ExperienceCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    card: {
      display: 'flex',
      flexDirection: {
        base: 'column',
        md: 'row',
      },
      h: {
        base: 'auto',
        md: '280px',
      },
      boxShadow: 'md',
    },
    stack: {
      display: 'flex',
      flexDirection: {
        base: 'column',
        md: 'row',
      },
      textDecoration: 'unset',
      textColor: 'unset',
      overflow: 'hidden',
      w: 'auto',
    },
    image: {
      h: {
        base: '220px',
        md: 'full',
      },
      maxW: {
        base: 'full',
        md: '230px',
        lg: '280px',
      },
      borderTopLeftRadius: 'lg',
      borderTopRightRadius: {
        base: 'lg',
        md: '0',
      },
      borderBottomLeftRadius: {
        base: '0',
        md: 'lg',
      },
    },
    content: {
      w: 'full',
      h: 'full',
      p: 4,
    },
    heading: {
      fontSize: {
        base: 'md',
        lg: 'lg',
      },
      fontWeight: 'medium',
      pb: {
        base: 4,
        md: 'unset',
      },
    },
    description: {
      fontSize: {
        base: 'xs',
        md: 's',
      },
    },
    duration: {
      pt: {
        base: 4,
        md: 'unset',
      },
    },
    pricingContainer: {
      w: 'auto',
      flexShrink: 0,
      p: 4,
      mr: {
        base: 'unset',
        md: 2,
      },
    },
  },
})
