import { helpers } from './base'

export const TicketOptions = helpers.defineMultiStyleConfig({
  baseStyle: {
    ticketOptions: {
      gap: 'xs',
      marginBottom: '2xl',
    },
    ticketOptionsResults: {
      gap: 'xs',
    },
  },
})
