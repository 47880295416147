/* eslint-disable sonarjs/no-duplicate-string */
import { overlayTipHelper } from './base'

export const OverlayTip = overlayTipHelper.defineMultiStyleConfig({
  baseStyle: overlayTipHelper.definePartsStyle({
    containerBorder: {
      borderColor: 'border.primary.default',
      borderRadius: '3xs',
    },
  }),
})
