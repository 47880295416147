import { helpers } from './base'

export const TravelerRoom = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    roomNumberTitle: {
      fontSize: 'var(--alto-sem-text-body-sm-font-size)',
      fontWeight: 'medium',
    },
    travellerLabel: {
      fontSize: 'var(--alto-sem-text-body-md-font-size)',
      fontWeight: 'normal',
    },
    agedLabel: {
      fontSize: 'var(--alto-sem-text-body-sm-font-size)',
      fontWeight: 'medium',
    },
  }),
})
