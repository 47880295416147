/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const MeetingPoints = helpers.defineMultiStyleConfig({
  baseStyle: {
    mainContainer: {
      borderRadius: 'sm',
      backgroundColor: 'bg.layer2.default',
      padding: 'lg',
    },
    secondaryHeader: {
      fontSize: 'md',
      fontWeight: 'semibold',
      mb: 'sm',
    },
    mainTitle: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      fontWeight: 'light',
    },
    locationGroup: {
      mt: 'sm',

      p: {
        fontSize: 'md',
      },
    },
  },
})
