import { acceptedCardsHelper } from './base'

export const AcceptedCards = acceptedCardsHelper.defineMultiStyleConfig({
  baseStyle: {
    acceptedCardWrapper: {
      bg: 'bg.layer3.default',
      borderRadius: 'sm',
    },
  },
})
