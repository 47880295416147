import { chakra, useMultiStyleConfig, VisuallyHidden, Box, Link } from '@chakra-ui/react'
import NextLink from 'next/link'
import { usePartner } from '~/context/partner'
import { Image } from '@reward-platform/lift/components'
import { useNavigationAnalytics } from '~/hooks/useNavigationAnalytics'
import { useAssetContent } from '~/hooks/useAssetContent/useAssetContent'
import { getPublicAssetSrc } from '~/utils/addBasePathToPath'
import useTranslations from './Footer.translations'

function Footer(): JSX.Element {
  const t = useTranslations()
  const styles = useMultiStyleConfig('Footer', {})
  const { onNavigate } = useNavigationAnalytics()

  const { theme: partner } = usePartner()
  const { url: modernSlaveryActUrl } = useAssetContent('modern-slavery-act')

  // TODO: Pull from contentful
  const links = [
    {
      href: '/contact-us',
      label: t.contactUs,
    },
    {
      href: '/privacy',
      label: t.privacy,
    },
    {
      href: '/security',
      label: t.security,
    },
    {
      href: '/accessibility',
      label: t.accessibility,
    },
    {
      href: '/terms-and-conditions',
      label: t.termsAndConditions,
    },
    {
      href: '/cookie-policy',
      label: t.cookiePolicy,
    },
    {
      href: modernSlaveryActUrl,
      label: t.modernSlaveryAct,
      isAssetLink: true,
    },
    {
      href: '/atol-protection',
      label: t.atolProtection,
    },
  ]

  const onLinkClick = (label: string) => {
    onNavigate({ navType: 'footer link', navAction: label.toLowerCase() })
  }

  return (
    <chakra.footer __css={styles.wrapper} id="footer" aria-labelledby="site-footer--label">
      <VisuallyHidden as="h2" id="site-footer--label">
        {t.label}
      </VisuallyHidden>

      <chakra.nav>
        <chakra.ul __css={styles.navList}>
          {links.map(({ href, label, isAssetLink }) =>
            href ? (
              <chakra.li __css={styles.navListItem} key={label}>
                <Link
                  sx={styles.navListLink}
                  href={href}
                  as={isAssetLink ? undefined : NextLink}
                  isExternal
                  onClick={() => onLinkClick(label)}
                >
                  {label}
                </Link>
              </chakra.li>
            ) : null
          )}
        </chakra.ul>
      </chakra.nav>

      <Box __css={styles.content}>
        <Box __css={styles.legal}>
          <chakra.p>
            {partner === 'british_airways'
              ? t.legalCopyrightLineBritishAirways
              : t.legalCopyrightLineAerLingus}
          </chakra.p>
        </Box>
        <Box __css={styles.logo}>
          <Image
            __css={styles.logoWrapper}
            src={getPublicAssetSrc('/assets/avios-logo.png')}
            alt="Avios"
            width={48}
            height={48}
            layout="responsive"
          />
          <chakra.p>{t.provider}</chakra.p>
        </Box>
      </Box>
    </chakra.footer>
  )
}

export default Footer
