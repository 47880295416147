/* eslint-disable sonarjs/no-duplicate-string */
import { replaceCarItemCardHelper } from './base'

const accentPrimary = 'fg.accent.primary'

export const ReplaceCarItemCard = replaceCarItemCardHelper.defineMultiStyleConfig({
  baseStyle: replaceCarItemCardHelper.definePartsStyle({
    iconsWrapper: {
      color: accentPrimary,
    },
    heading: {
      p: {
        color: accentPrimary,
      },
      h4: {
        fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
        color: accentPrimary,
      },
    },
    content: {
      img: {
        borderRadius: 'lg',
      },
    },
    includedContainer: {
      bg: 'bg.layer1.default',
    },
    includedTextContainer: {
      p: {
        fontSize: 'var(--alto-sem-text-body-sm-font-size)',
        fontWeight: 'normal',
        color: accentPrimary,
        _first: {
          fontWeight: 'light',
        },
      },
    },
  }),
})
