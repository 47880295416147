import { DatadogLogMetaContext, createDatadogMetaContext } from '@reward-platform/logger/utils'
import { ZodIssue } from 'zod'
import { createDatadogLogger } from '~/utils/logger'
import { isClient } from '~/utils/envChecks'
import { formatLogs } from '~/utils/datadog'
import { ErrorCode } from './clientErrors'

const DEFAULT_BUSINESS_MESSAGE = 'Something went wrong, please check the details and try again'

export abstract class GenericClientError<E extends Error = Error> extends Error {
  private errorDescription: string

  public developerMessage: string

  public businessMessage: string

  public code: string

  public httpStatus?: number

  public validationErrors?: ZodIssue[]

  public originalError?: Error

  public fatal: boolean

  constructor(code: ErrorCode, error: E) {
    super()
    this.name = error.name
    this.message = error.message
    this.stack = error.stack
    this.code = error.name
    this.errorDescription = code
    this.developerMessage = error.message
    this.businessMessage = DEFAULT_BUSINESS_MESSAGE
    this.fatal = false
  }

  public logToDatadog(meta?: DatadogLogMetaContext, additionalData = {}) {
    const metaContext = meta ? createDatadogMetaContext(meta) : {}
    const errorContext = {
      message: this.message,
      stack: this.stack,
      code: this.code,
      description: this.errorDescription,
      httpStatus: this.httpStatus,
      originalError: this.originalError,
      developerMessage: this.developerMessage,
      businessMessage: this.businessMessage,
      validationErrors: this.validationErrors,
    }

    const context = { ...additionalData, ...metaContext, error: errorContext }

    if (isClient()) {
      const logger = createDatadogLogger('client-error-handler', { level: 'error' })

      logger.error(`ClientError: ${this.message}`, context, this.originalError)
    } else {
      const message = `ServerError: ${this.message}`
      const serverContext = { message, ...context }
      const level = this.fatal ? 'critical' : 'error'
      // eslint-disable-next-line no-console
      console.log(formatLogs({ level, ...serverContext }))
    }
  }
}
