/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const WhatToExpect = helpers.defineMultiStyleConfig({
  baseStyle: {
    passBy: {
      fontWeight: 'light',
    },
    header: {
      color: 'fg.primary',
    },
    optionalText: {
      lineHeight: 5,
      fontSize: 'sm',
      fontWeight: 'light',
    },
    durationContainer: {
      backgroundColor: 'bg.accent.subtle.default',
      padding: 'var(--alto-sem-space-4xs) var(--alto-sem-space-2xs)',
      borderRadius: 'lg',
    },
    whatToExpectContainer: {
      bg: 'bg.layer2.default',
      borderRadius: 'sm',
      p: 'lg',

      '> ul': {
        mt: 10,
      },
      '> ul b': {
        fontWeight: 'normal',
      },
      '> ul li+li': {
        mt: 10,
      },
    },
    whatToExpectTitle: {
      color: 'fg.primary',
      fontWeight: 'light',
    },
  },
})
