import { z } from 'zod'
import { ThemeMap } from '~/themes/chakra'
import { isClient, isLocal } from '~/utils/envChecks'
import { PlatformError } from '~/utils/errors'

type Iata = 'VY' | 'EI' | 'BA'
type Domain = 'aerlingus' | 'vueling' | 'britishairways' | 'avios'

export interface Partner {
  code: Iata
  slug: string
  provider: string
  name: string
  domain: Domain
  whitelist: string[]
  theme: keyof ThemeMap
  tag: keyof ThemeMap
  basePath?: string
}

const parseWhitelist = (listString?: string): string[] =>
  z
    .string()
    // TODO: Make mandatory, remove the default value, and add a minimum length
    .optional()
    .default('')
    .parse(listString)
    .split(',')
    .map((domain) => domain.trim())

const aerLingusPartnerConfig: Partner = {
  code: 'EI',
  tag: 'aer_lingus',
  slug: 'AER_LINGUS',
  provider: 'RP_EI',
  name: 'Aer Lingus',
  domain: 'aerlingus',
  whitelist: parseWhitelist(process.env.NEXT_PUBLIC_EI_DOMAIN_WHITELIST),
  theme: 'aer_lingus',
}

const britishAirwaysPartnerConfig: Partner = {
  code: 'BA',
  tag: 'british_airways',
  slug: 'BRITISH_AIRWAYS',
  provider: 'RP_BA',
  name: 'British Airways',
  domain: 'britishairways',
  whitelist: parseWhitelist(process.env.NEXT_PUBLIC_BA_DOMAIN_WHITELIST),
  theme: 'british_airways',
}

const aviosPartnerConfig: Partner = {
  ...britishAirwaysPartnerConfig,
  name: 'Avios',
  whitelist: parseWhitelist(process.env.NEXT_PUBLIC_AVIOS_DOMAIN_WHITELIST),
  theme: 'avios',
  domain: 'avios',
  basePath: process.env.NEXT_PUBLIC_BASE_PATH,
}

export const partners: Partner[] = [
  // TODO: remove the config for VY?
  {
    code: 'VY',
    tag: 'vueling',
    slug: 'VUELING',
    provider: 'RP_VY',
    name: 'Vueling',
    domain: 'vueling',
    whitelist: [''],
    theme: 'vueling',
  },
  aerLingusPartnerConfig,
  britishAirwaysPartnerConfig,
  aviosPartnerConfig,
]

const DEFAULT_PARTNER = aviosPartnerConfig

export const getPartner = (host?: string) => {
  const maybeHost = host ?? window?.location?.host
  if (!maybeHost) {
    const from = isClient() ? 'window.location (client)' : 'headers (server)'
    const hostError = new PlatformError(`No host found from ${from}`)
    hostError.logToDatadog()
    throw hostError
  }

  const maybePartner = partners.find((partner) => partner.whitelist.includes(maybeHost))
  if (!maybePartner) {
    const partnerError = new PlatformError(`No partner found that matches host: ${maybeHost}`)
    partnerError.logToDatadog()

    if (isLocal()) {
      throw partnerError
    }

    return DEFAULT_PARTNER
  }

  return maybePartner
}
