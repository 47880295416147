import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react'
import {
  AutoComplete,
  AutoCompleteLocationOption,
} from '@reward-platform/lift/components/AutoComplete/themes/base'
import { Card } from '@reward-platform/lift/components/Card/themes/base'
import { Carousel } from '@reward-platform/lift/components/Carousel/themes/base'
import { ListBox } from '@reward-platform/lift/components/ChakraListBox/themes/base'
import { Accordion } from '@reward-platform/lift/components/ChakraAccordion/themes/base'
import { Alert } from '@reward-platform/lift/components/Alert/themes/base'
import { Button } from '@reward-platform/lift/components/ChakraButton/themes/base'
import { ChakraCollapsibleButton } from '@reward-platform/lift/components/ChakraCollapsibleButton/themes/base'
import { ChakraDefinitionGroup } from '@reward-platform/lift/components/ChakraDefinitionGroup/themes/base'
import { Form } from '@reward-platform/lift/components/ChakraField/themes/base'
import { ChakraPopover } from '@reward-platform/lift/components/ChakraPopover/themes/base'
import { RichText } from '@reward-platform/lift/components/ChakraRichText/themes/base'
import { Checkbox } from '@reward-platform/lift/components/Checkbox/themes/base'
import { Chip } from '@reward-platform/lift/components/Chip/themes/base'
import { Collapsible } from '@reward-platform/lift/components/Collapsible/themes/base'
import { CrossSellingCard } from '@reward-platform/lift/components/CrossSellingCard/themes/base'
import {
  DatePicker,
  DatePickerGlobalTheme,
} from '@reward-platform/lift/components/DatePicker/themes/base'
import { Divider } from '@reward-platform/lift/components/Divider/themes/base'
import { ErrorMessage } from '@reward-platform/lift/components/ErrorMessage/themes/base'
import { FieldGroup } from '@reward-platform/lift/components/FieldGroup/themes/base'
import { Fieldset } from '@reward-platform/lift/components/Fieldset/themes/base'
import { ImageCard } from '@reward-platform/lift/components/ImageCard/themes/base'
import { ImageGallery } from '@reward-platform/lift/components/ImageGallery/themes/base'
import { ImageModal } from '@reward-platform/lift/components/ImageModal/themes/base'
import { InfoMessage } from '@reward-platform/lift/components/InfoMessage/themes/base'
import { List } from '@reward-platform/lift/components/List/themes/base'
import { MaintenanceMode } from '@reward-platform/lift/components/MaintenanceMode/themes/base'
import { TextMarker } from '@reward-platform/lift/components/Map/themes/base'
import { MobileModal } from '@reward-platform/lift/components/MobileModal/themes/base'
import { Modal } from '@reward-platform/lift/components/Modal/themes/base'
import { NotificationMessage } from '@reward-platform/lift/components/NotificationMessage/themes/base'
import { OverlayTip } from '@reward-platform/lift/components/OverlayTip/themes/base'
import { Pagination } from '@reward-platform/lift/components/Pagination/themes/base'
import { QuantityPicker } from '@reward-platform/lift/components/QuantityPicker/themes/base'
import { RadioButton } from '@reward-platform/lift/components/RadioButton/themes/base'
import { RangeDatePicker } from '@reward-platform/lift/components/RangeDatePicker/themes/base'
import { Ratings } from '@reward-platform/lift/components/Ratings/themes/base'
import { SelectBox } from '@reward-platform/lift/components/SelectBox/themes/base'
import { Skeleton, SkeletonCard } from '@reward-platform/lift/components/SkeletonCard/themes/base'
import { Spinner } from '@reward-platform/lift/components/Spinner/themes/base'
import { StepperCircle } from '@reward-platform/lift/components/Stepper/StepperCircle/themes/base'
import { Stepper } from '@reward-platform/lift/components/Stepper/themes/base'
import { Tabs } from '@reward-platform/lift/components/Tabs/themes/base'
import { TelevisionButton } from '@reward-platform/lift/components/TelevisionButton/themes/base'
import { Tooltip } from '@reward-platform/lift/components/Tooltip/themes/base'
import { WarningMessage } from '@reward-platform/lift/components/WarningMessage/themes/base'
import { deviceSizes } from '@reward-platform/lift/theme/deviceSizes'
import { Combobox } from '@reward-platform/lift/components/ComboBox/themes/base'
import { ActivityMenu } from '~/components/experiences/details/ActivityMenu/themes/base'
import { CustomerReviewList } from '~/components/experiences/details/CustomerReviewList/themes/base'
import { HotelDetails } from '~/components/hotels/details/themes/base'
import { ExperienceDetails } from '~/components/experiences/details/details/themes/base'
import { ItemCardImage } from '~/components/ItemCardImage/themes/base'
import { ItemCardImageWithLogo } from '~/components/ItemCardImageWithLogo/themes/base'
import { CancelOrderConfirmationWrapper } from '~/components/order-history/CancelOrderConfirmModal/themes/base'
import { CancelOrderModal } from '~/components/order-history/CancelOrderModal/themes/base'
import { CancelOrderPaymentForm } from '~/components/order-history/CancelOrderPaymentForm/themes/base'
import { StatusBadge } from '~/components/order-history/StatusBadge/themes/base'
import { AdditionalDetailsForm } from '~/components/orders/AdditionalDetailsForm/themes/base'
import { AncillaryBasketItemDetails } from '~/components/orders/AncillaryBasketItemDetails/themes/base'
import { AtolEligible } from '~/components/orders/AtolEligible/themes/base'
import { CarBasketItemCard } from '~/components/orders/BasketItemCard/CarBasketItemCard/themes/base'
import { ExperienceBasketItemCard } from '~/components/orders/BasketItemCard/ExperienceBasketItemCard/themes/base'
import { FlightBasketItemCard } from '~/components/orders/BasketItemCard/FlightBasketItemCard/themes/base'
import { HotelBasketItemCard } from '~/components/orders/BasketItemCard/HotelBasketItemCard/themes/base'
import { BasketItemCard } from '~/components/orders/BasketItemCard/themes/base'
import { BasketItemPaymentOptionsSelect } from '~/components/orders/BasketItemPaymentOptionsSelect/themes/base'
import { BasketItemSummary } from '~/components/orders/BasketItemSummary/themes/base'
import { BookingStatus } from '~/components/orders/BookingStatus/themes/base'
import { Itinerary } from '~/components/orders/Itinerary/themes/base'
import { AcceptedCards } from '~/components/orders/PaymentForm/AcceptedCards/themes/base'
import { PriceBreakdownContainer } from '~/components/orders/PaymentForm/PaymentOrderPriceBreakdown/themes/base'
import { PaymentFormContainer } from '~/components/orders/PaymentForm/themes/base'
import { PriceBreakdownSticky } from '~/components/orders/PriceBreakdown/PriceBreakdownSticky/themes/base'
import { PriceBreakdownSummary } from '~/components/orders/PriceBreakdown/PriceBreakdownSummary/themes/base'
import { PriceBreakdownSummaryModalButton } from '~/components/orders/PriceBreakdown/PriceBreakdownSummaryModalButton/themes/base'
import { PriceBreakdownTotalDue } from '~/components/orders/PriceBreakdown/PriceBreakdownTotalDue/theme/base'
import { PriceBreakdown } from '~/components/orders/PriceBreakdown/themes/base'
import { PriceBreakdownCollapsibleContent } from '~/components/orders/shared/PriceBreakdownCollapsibleContent/themes/base'
import { PriceBreakdownCollectedAviosAmount } from '~/components/orders/shared/PriceBreakdownCollectedAviosAmount/themes/base'
import { PriceBreakdownRowItem } from '~/components/orders/shared/PriceBreakdownRowItem/theme/base'
import { TravelerInformationForm } from '~/components/orders/TravelerInformationForm/themes/base'
import { Banner } from '~/components/search-and-results/Banner/themes/base'
import { CarAdditionalProductCard } from '~/components/search-and-results/CarAdditionalProductCard/themes/base'
import { CarCard } from '~/components/search-and-results/CarCard/themes/base'
import { CarPriceBar } from '~/components/search-and-results/CarPriceBar/themes/base'
import { DetailsPageNav } from '~/components/search-and-results/DetailsPageNav/themes/base'
import { PriceInfo } from '~/components/search-and-results/FlightCard/PriceInfo/themes/base'
import { FlightCard } from '~/components/search-and-results/FlightCard/themes/base'
import { FlightCardBanner } from '~/components/search-and-results/FlightCardBanner/themes/base'
import { FlightJourneyHeader } from '~/components/search-and-results/FlightJourneyHeader/themes/base'
import { ContinueButton } from '~/components/search-and-results/ContinueButton/themes/base'
import { ShowHideButton } from '~/components/search-and-results/ShowHideButton/themes/base'
import { FlightsResults } from '~/components/search-and-results/FlightResults/themes/base'
import { FlightTravellerInput } from '~/components/search-and-results/FlightTravellerInput/themes/base'
import { FlightPriceFooter } from '~/components/search-and-results/FlightPriceFooter/themes/base'
import { FlightPaymentOptions } from '~/components/search-and-results/FlightPaymentOptions/themes/base'
import { HotelCard } from '~/components/search-and-results/HotelCard/themes/base'
import { HotelNearbyAttractions } from '~/components/search-and-results/HotelNearbyAttractions/themes/base'
import { AncillaryPhotos } from '~/components/search-and-results/AncillaryPhotos/themes/base'
import { HotelReviewBreakdown } from '~/components/search-and-results/HotelReviewBreakdown/themes/base'
import { HotelRooms } from '~/components/search-and-results/HotelRooms/themes/base'
import { HotelSummary } from '~/components/search-and-results/HotelSummary/themes/base'
import { IntroSection } from '~/components/search-and-results/IntroSection/themes/base'
import { LocationDetailsModalWrapper } from '~/components/search-and-results/LocationDetailsModal/themes/base'
import { NoFlightResults } from '~/components/search-and-results/NoFlightResults/themes/base'
import { NoResults } from '~/components/search-and-results/NoResults/themes/base'
import { Label } from '~/components/search-and-results/Label/themes/base'
import { PopularAmenities } from '~/components/search-and-results/PopularAmenities/themes/base'
import { RecommendedSection } from '~/components/search-and-results/RecommendedSection/themes/base'
import { ReplaceCarItemCard } from '~/components/search-and-results/ReplaceCarItemCard/themes/base'
import { ReplaceExperienceItemCard } from '~/components/search-and-results/ReplaceExperienceItemCard/themes/base'
import { ReplaceHotelItemCard } from '~/components/search-and-results/ReplaceHotelItemCard/themes/base'
import { ReplaceItemModal } from '~/components/search-and-results/ReplaceItemModal/themes/base'
import { ResultsMapView } from '~/components/search-and-results/ResultsMapView/themes/base'
import { ResultsPageTemplate } from '~/components/search-and-results/ResultsPageTemplate/themes/base'
import { RoomDetailsCard } from '~/components/search-and-results/RoomDetailsCard/themes/base'
import { RoomMoreDetailsModal } from '~/components/search-and-results/RoomMoreDetailsModal/themes/base'
import { RoomRateSelector } from '~/components/search-and-results/RoomRateSelector/themes/base'
import { SearchExperienceForm } from '~/components/search-and-results/SearchExperienceForm/themes/base'
import { SearchFlightForm } from '~/components/search-and-results/SearchFlightForm/themes/base'
import { EditSearchResultsForm } from '~/components/search-and-results/SearchHotelForm/EditSearchResultsForm/themes/base'
import { SearchHotelForm } from '~/components/search-and-results/SearchHotelForm/themes/base'
import { ChildrenAgePicker } from '~/components/shared/ChildrenAgePicker/themes/base'
import { TravelerRoomPicker } from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/themes/base'
import { TravelerRoom } from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/TravelerRoom/themes/base'
import { StaticMap } from '~/components/search-and-results/StaticMap/themes/base'
import { AmenitiesList } from '~/components/shared/AmenitiesList/themes/base'
import { BackTo } from '~/components/shared/BackTo/themes/base'
import { CollapsibleForm } from '~/components/shared/CollapsibleForm/themes/base'
import { ConfirmationModal } from '~/components/shared/ConfirmationModal/themes/base'
import { Filters } from '~/components/shared/Filters/themes/base'
import { Footer } from '~/components/shared/Footer/themes/base'
import { GuestRatings } from '~/components/shared/GuestRatings/themes/base'
import { Header } from '~/components/shared/Header/themes/base'
import { Menu } from '~/components/shared/HeaderMenu/themes/base'
import { Hero } from '~/components/shared/Hero/themes/base'
import { IconValuePair } from '~/components/shared/IconValuePair/theme/base'
import { Layout } from '~/components/shared/Layout/themes/base'
import { Logo } from '~/components/shared/Logo/themes/base'
import { PageTitle } from '~/components/shared/PageTitle/themes/base'
import { PaymentOptionsContainer } from '~/components/shared/PaymentOptionsContainer/themes/base'
import { PolicyPage } from '~/components/shared/PolicyPage/themes/base'
import { Progress, ProgressBar } from '~/components/shared/ProgressBar/themes/base'
import { Scrim } from '~/components/shared/Scrim/themes/base'
import { SectionNavigation } from '~/components/shared/SectionNavigation/themes/base'
import { SpinnerOnPage } from '~/components/shared/SpinnerOnPage/themes/base'
import { SpinnerOverlay } from '~/components/shared/SpinnerOverlay/themes/base'
import { TravelerQuantityPicker } from '~/components/shared/TravelerQuantityPicker/theme/base'
import { TravelerQuantityPickerComboBox } from '~/components/shared/TravelerQuantityPickerComboBox/themes/base'
import { CarDetails } from '~/components/cars/details/themes/base'
import { FlightBasketItemDetails } from '~/components/orders/FlightBasketItemDetails/themes/base'
import { Title } from '~/components/shared/PageContainer/themes/base'
import { ExperienceInformation } from '~/components/experiences/details/ExperienceInformation/themes/base'
import { SearchCarHireForm } from '~/components/search-and-results/SearchCarHireForm/themes/base'
import { FlightItineraryProp } from '~/components/orders/Itinerary/FlightItineraryProp/themes/base'
import { FlightServiceItineraryProp } from '~/components/orders/Itinerary/FlightServiceItineraryProp/themes/base'
import { SearchResultCard } from '~/components/experiences/details/SearchResultCard/themes/base'
import { FeaturesList } from '~/components/experiences/details/FeaturesList/themes/base'
import { ExperienceTitle } from '~/components/experiences/details/ExperienceTitle/themes/base'
import { PricingAndSearchCard } from '~/components/experiences/details/PricingAndSearchCard/themes/base'
import { RouteInfo } from '~/components/experiences/details/RouteInfo/themes/base'
import { DayList } from '~/components/experiences/details/DayList/themes/base'
import { PointsOfInterest } from '~/components/experiences/details/PointsOfInterestList/themes/base'
import { CarItemExtrasPopover } from '~/components/shared/CarItemExtrasPopover/themes/base'
import { TicketOptions } from '~/components/experiences/details/TicketOptions/themes/base'
import { ErrorMessageWrapper } from '~/components/ErrorMessageWrapper/themes/base'
import { FlightEditSearch } from '~/components/search-and-results/FlightEditSearch/themes/base'
import { BoundDetails } from '~/components/search-and-results/FlightPriceFooter/BoundDetails/themes/base'
import { SearchFieldSwapButton } from '~/components/search-and-results/SearchFieldSwapButton/themes/base'
import { MeetingPoints } from '~/components/experiences/details/MeetingPoints/themes/base'
import { ExternalLink } from '~/components/shared/ExternalLink/themes/base'
import { Pricing, PricingOptions } from '~/components/search-and-results/Pricing/themes/base'
import { FlightBreakdown } from '~/components/search-and-results/FlightPriceFooter/FlightBreakdown/themes/base'
import { FlightDetailsModal } from '~/components/search-and-results/FlightDetailsModal/themes/base'
import { FlightSelect } from '~/components/search-and-results/FlightSelect/themes/base'
import { Badge } from '~/components/search-and-results/Badge/themes/base'
import { FlightAviosOnlyBanner } from '~/components/search-and-results/FlightAviosOnlyBanner/themes/base'
import { Snackbar } from '@reward-platform/lift/components/Snackbar/themes/base'
import { RoomsBanner } from '~/components/search-and-results/RoomsBanner/themes/base'
import { BasketTravellersLabel } from '~/components/orders/BasketItemCard/BasketTravellersLabel/themes/base'
import { HotelAdditionalDetails } from '~/components/orders/AdditionalDetailsForm/HotelAdditionalDetails/themes/base'
import { WhatToExpect } from '~/components/experiences/details/WhatToExpect/themes/base'
import { StickyBanner } from '@reward-platform/lift/components/StickyBanner/themes/base'
import { RecentSearches } from '~/components/search-and-results/RecentSearches/themes/base'
import { ShareButton } from '~/components/shared/ShareButton/themes/base'
import { OrderListTable } from '~/components/order-history/OrderListTable/themes/base'
import { CarHireAdditionalDetails } from '~/components/orders/AdditionalDetailsForm/AccountHolderContactDetails/CarHireAdditionalDetails/themes/base'
import { ContentBadge } from '@reward-platform/lift/components/ContentBadge/themes/base'
import { DismissibleModal } from '@reward-platform/lift/components/DismissibleModal/themes/base'
import { PopularDestinations } from '~/components/search-and-results/PopularDestinations/themes/base'
import { CardBanner } from '@reward-platform/lift/components/CardBanner/themes/base'
import { Dropdown } from '~/components/shared/Dropdown/themes/base'
import {
  UpgradeBasketItem,
  UpgradeBasketAmenities,
} from '~/components/orders/UpgradeBasketItem/themes/base'
import { RefundabilityButton } from '~/components/orders/BasketItemCard/RefundabilityButton/themes/base'
import { ChatWidgetMock } from '~/components/shared/ChatWidgetMock/themes/base'
import { ExperienceCard } from '~/components/search-and-results/ExperienceCard/themes/base'

const FormError: ComponentStyleConfig = {
  baseStyle: {
    text: {
      color: 'error.primary',
    },
  },
}

export const baseTheme = extendTheme({
  styles: {
    global: {
      'html, body, div#__next': {
        lineHeight: 1.4,
      },
      // In the next Chakra release the popover z-index will be set to 'popover'
      // by default https://github.com/chakra-ui/chakra-ui/commit/f72b2b681182a3810853687201396c7b18163ed3
      '.chakra-popover__popper': {
        zIndex: 'popover !important',
      },
      ...DatePickerGlobalTheme,
    },
  },
  breakpoints: {
    xs: deviceSizes.xsmall,
    sm: deviceSizes.small,
    md: deviceSizes.medium,
    lg: deviceSizes.large,
    xl: deviceSizes.xlarge,
  },
  spacing: {
    small: 2,
  },
  components: {
    Button,
    FormError,
    BasketItemCard,
    RefundabilityButton,
    AncillaryBasketItemDetails,
    ListBox,
    CancelOrderModal,
    PaymentFormContainer,
    PriceBreakdownContainer,
    CancelOrderConfirmationWrapper,
    ChakraDefinitionGroup,
    PriceBreakdown,
    PriceBreakdownSticky,
    PriceBreakdownSummaryModalButton,
    PriceBreakdownCollectedAviosAmount,
    BasketItemPaymentOptionsSelect,
    LocationDetailsModalWrapper,
    BasketItemSummary,
    BookingStatus,
    PriceBreakdownRowItem,
    PriceBreakdownTotalDue,
    PriceBreakdownSummary,
    Stepper,
    StepperCircle,
    Pagination,
    ChakraPopover,
    Card,
    Accordion,
    CarBasketItemCard,
    PriceBreakdownCollapsibleContent,
    ChakraCollapsibleButton,
    Chip,
    FlightsResults,
    ErrorMessage,
    ActivityMenu,
    Fieldset,
    ImageCard,
    DatePicker,
    RangeDatePicker,
    MobileModal,
    Checkbox,
    RichText,
    Menu,
    PaymentOptionsContainer,
    List,
    Alert,
    FieldGroup,
    SkeletonCard,
    Skeleton,
    Footer,
    IntroSection,
    TelevisionButton,
    Divider,
    Hero,
    StaticMap,
    Spinner,
    SpinnerOverlay,
    SpinnerOnPage,
    ImageGallery,
    RadioButton,
    Tooltip,
    ProgressBar,
    Progress,
    PolicyPage,
    Collapsible,
    AmenitiesList,
    PopularAmenities,
    CarPriceBar,
    NoResults,
    FlightPriceFooter,
    FlightPaymentOptions,
    ShowHideButton,
    HotelNearbyAttractions,
    SectionNavigation,
    ReplaceExperienceItemCard,
    MaintenanceMode,
    NotificationMessage,
    InfoMessage,
    Filters,
    Label,
    Header,
    AncillaryPhotos,
    ImageModal,
    Carousel,
    HotelSummary,
    GuestRatings,
    QuantityPicker,
    BackTo,
    Pricing,
    PricingOptions,
    Ratings,
    HotelCard,
    NoFlightResults,
    CardBanner,
    WarningMessage,
    TravelerInformationForm,
    AdditionalDetailsForm,
    CrossSellingCard,
    TravelerQuantityPicker,
    ChildrenAgePicker,
    Modal,
    IconValuePair,
    TravelerRoomPicker,
    TravelerRoom,
    HotelBasketItemCard,
    ItemCardImage,
    ExperienceBasketItemCard,
    Tabs,
    Itinerary,
    CustomerReviewList,
    CollapsibleForm,
    Logo,
    TextMarker,
    RecommendedSection,
    ReplaceItemModal,
    ReplaceHotelItemCard,
    ReplaceCarItemCard,
    HotelReviewBreakdown,
    ItemCardImageWithLogo,
    SelectBox,
    SearchHotelForm,
    EditSearchResultsForm,
    SearchExperienceForm,
    SearchFlightForm,
    CarCard,
    DetailsPageNav,
    FlightCard,
    FlightCardBanner,
    PriceInfo,
    Form,
    Banner,
    ConfirmationModal,
    FlightJourneyHeader,
    AutoComplete,
    AcceptedCards,
    RoomRateSelector,
    FlightTravellerInput,
    RoomMoreDetailsModal,
    ResultsMapView,
    AutoCompleteLocationOption,
    ResultsPageTemplate,
    Scrim,
    TravelerQuantityPickerComboBox,
    HotelRooms,
    CarAdditionalProductCard,
    Layout,
    RoomDetailsCard,
    OrderListTable,
    HotelDetails,
    ExperienceDetails,
    ContinueButton,
    CancelOrderPaymentForm,
    PageTitle,
    StatusBadge,
    FlightBasketItemCard,
    CarDetails,
    FlightBasketItemDetails,
    Title,
    ExperienceInformation,
    SearchCarHireForm,
    FlightItineraryProp,
    FlightServiceItineraryProp,
    SearchResultCard,
    FeaturesList,
    ExperienceTitle,
    PricingAndSearchCard,
    Combobox,
    RouteInfo,
    DayList,
    PointsOfInterest,
    CarItemExtrasPopover,
    TicketOptions,
    ErrorMessageWrapper,
    FlightEditSearch,
    BoundDetails,
    SearchFieldSwapButton,
    MeetingPoints,
    ExternalLink,
    FlightBreakdown,
    FlightDetailsModal,
    FlightSelect,
    FlightAviosOnlyBanner,
    Badge,
    OverlayTip,
    Snackbar,
    RoomsBanner,
    BasketTravellersLabel,
    StickyBanner,
    HotelAdditionalDetails,
    AtolEligible,
    WhatToExpect,
    RecentSearches,
    ShareButton,
    CarHireAdditionalDetails,
    ContentBadge,
    DismissibleModal,
    PopularDestinations,
    Dropdown,
    UpgradeBasketItem,
    UpgradeBasketAmenities,
    ChatWidgetMock,
    ExperienceCard,
  },
})
