import { css } from '@emotion/react'

import '@alto-avios/alto-tokens/web-ssr/avios/fonts.css'
import '@alto-avios/alto-tokens/web-ssr/avios/light.css'
import '@alto-avios/alto-tokens/web-ssr/avios/dark.css'
import '@alto-avios/alto-tokens/web-ssr/avios/breakpoints/xs.css'
import '@alto-avios/alto-tokens/web-ssr/avios/breakpoints/sm.css'
import '@alto-avios/alto-tokens/web-ssr/avios/breakpoints/md.css'
import '@alto-avios/alto-tokens/web-ssr/avios/breakpoints/lg.css'
import '@alto-avios/alto-tokens/web-ssr/avios/breakpoints/xl.css'

const variables = css`
  /* @import url('@alto-avios/alto-tokens/web-ssr/avios/fonts.css');
  @import url('@alto-avios/alto-tokens/web-ssr/avios/light.css');
  @import url('@alto-avios/alto-tokens/web-ssr/avios/dark.css');
  @import url('@alto-avios/alto-tokens/web-ssr/avios/breakpoints/xs.css');
  @import url('@alto-avios/alto-tokens/web-ssr/avios/breakpoints/sm.css');
  @import url('@alto-avios/alto-tokens/web-ssr/avios/breakpoints/md.css');
  @import url('@alto-avios/alto-tokens/web-ssr/avios/breakpoints/lg.css');
  @import url('@alto-avios/alto-tokens/web-ssr/avios/breakpoints/xl.css'); */
`

export default variables
