/* eslint-disable sonarjs/no-duplicate-string */
import { priceBreakdownRowItemHelper } from './base'

const buttonStyle = {
  bg: 'bg.accent.subtle.default',
  borderRadius: 'xs',
  padding: '2xs',
  fontSize: 'xs',
  lineHeight: 'var(--alto-sem-text-body-xs-line-height)',
  color: 'fg.accent.onSubtle',
}

export const PriceBreakdownRowItem = priceBreakdownRowItemHelper.defineMultiStyleConfig({
  baseStyle: {
    priceBreakdownRowTitleLine: {
      fontSize: 'xs',
      fontWeight: 'semibold',
      lineHeight: 'var(--alto-sem-text-body-xs-line-height)',
      color: 'fg.accent.secondary',
    },
    priceBreakdownRowLine: {
      '> div': {
        ...buttonStyle,
        '> span': {
          _last: {
            fontWeight: 'semibold',
          },
        },
      },
      button: {
        ...buttonStyle,
        border: 'none',
        cursor: 'pointer',
        '> span': {
          _first: {
            fontSize: 'xs',
            textAlign: 'left',
          },
          _last: {
            fontWeight: 'semibold',
          },
        },
      },
    },
    priceBreakdownCollapsible: {
      button: {
        ...buttonStyle,
        border: 'none',
        cursor: 'pointer',
        '> span': {
          _first: {
            fontSize: 'xs',
          },
          _last: {
            fontWeight: 'semibold',
          },
        },
      },
      '> div': {
        color: 'fg.accent.onSubtle',
        fontSize: 'xs',
        p: '2xs',
        pt: 0,
      },
      _expanded: {
        bg: 'bg.accent.subtle.active',
        rounded: 'xs',
        button: {
          bg: 'bg.accent.subtle.active',
        },
      },
    },
  },
})
