/* eslint-disable sonarjs/no-duplicate-string */
import { ComponentStyleConfig } from '@chakra-ui/react'

export const StepperCircle: ComponentStyleConfig = {
  baseStyle: {
    borderColor: 'bg.accent.vibrant.default',
    '> p': {
      fontWeight: 'medium',
      fontSize: { base: 'md', md: 'lg' },
    },
    '> svg': {
      fontSize: { base: 'md', md: 'lg' },
    },
  },
  variants: {
    default: { bg: 'bg.layer2.default', color: 'fg.accent.secondary' },
    completed: { bg: 'bg.accent.vibrant.default', color: 'fg.accent.onVibrant' },
    selected: { bg: 'bg.accent.vibrant.default', color: 'fg.accent.onVibrant' },
  },
}
