/* eslint-disable sonarjs/no-duplicate-string */
import { AlertHelper } from './base'

export const Alert = AlertHelper.defineMultiStyleConfig({
  baseStyle: AlertHelper.definePartsStyle({
    container: {
      '&[data-status="error"]': {
        borderColor: 'border.critical',
        bgColor: 'bg.critical.subtle.default',
      },
      '&[data-status="info"]': {
        borderColor: 'border.information',
        bgColor: 'bg.information.subtle.default',
      },
      '&[data-status="success"]': {
        borderColor: 'border.success',
        bgColor: 'bg.success.subtle.default',
      },
      '&[data-status="neutral"]': {
        borderColor: 'border.primary.default',
        bgColor: 'bg.layer1.default',
      },
      '&[data-status="warning"]': {
        borderColor: 'border.warning',
        bgColor: 'bg.warning.subtle.default',
      },
    },
    title: {
      '&[data-status="error"]': {
        color: 'fg.critical.primary',
      },
      '&[data-status="info"]': {
        color: 'fg.information.primary',
      },
      '&[data-status="success"]': {
        color: 'fg.success.primary',
      },
      '&[data-status="neutral"]': {
        color: 'color.fg.primary',
      },
      '&[data-status="warning"]': {
        color: 'fg.warning.primary',
      },
    },
    description: {
      '&[data-status="info"]': {
        color: 'fg.information.primary',
      },
    },
    icon: {
      '&[data-status="error"]': {
        color: 'fg.critical.primary',
      },
      '&[data-status="info"]': {
        color: 'fg.information.primary',
      },
      '&[data-status="success"]': {
        color: 'fg.success.primary',
      },
      '&[data-status="neutral"]': {
        color: 'color.fg.primary',
      },
      '&[data-status="warning"]': {
        color: 'fg.warning.primary',
      },
    },
  }),
  variants: {
    promo: AlertHelper.definePartsStyle({
      container: {
        '&[data-status]': {
          borderColor: 'border.accent',
          bgColor: 'bg.accent.subtle.default',
          borderWidth: 1,
        },
      },
      description: {
        fontWeight: 'medium',
        fontSize: 'md',
        lineHeight: 1.2,
      },
      icon: {
        '&[data-status="neutral"]': { color: 'fg.accent.primary' },
      },
    }),
    upgrade: AlertHelper.definePartsStyle({
      container: {
        '&[data-status]': {
          borderColor: 'border.accent',
          bgColor: 'bg.accent.subtle.default',
          borderWidth: 1,
        },
      },
      description: {
        fontWeight: 'medium',
        fontSize: 'md',
        lineHeight: 1.2,
      },
      icon: {
        '&[data-status="neutral"]': { color: 'fg.accent.primary' },
      },
    }),
  },
})
