import { Partner } from '~/services/partnerService'

import britishAirwaysFontFaces from './britishAirways.fonts'
import aerLingusFontFaces from './aerLingus.fonts'
import aviosFontFaces from './avios.fonts'

import { PartnerStylesMap } from '../styles.types'

const fontsMap: PartnerStylesMap = {
  aer_lingus: aerLingusFontFaces,
  vueling: null,
  british_airways: britishAirwaysFontFaces,
  avios: aviosFontFaces,
}

export const getPartnerFontFaces = (partnerTheme: Partner['theme']) => fontsMap[partnerTheme]
