import { Partner } from '~/services/partnerService'

import aviosVariables from './avios.variables'

import { PartnerStylesMap } from '../styles.types'

const variablesMap: PartnerStylesMap = {
  aer_lingus: null,
  vueling: null,
  british_airways: null,
  avios: aviosVariables,
}

export const getPartnerVariables = (partnerTheme: Partner['theme']) => variablesMap[partnerTheme]
