import { helpers } from './base'

export const PricingAndSearchCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      backgroundColor: 'bg.layer2.default',
      borderRadius: 'sm',
      gap: 'md',
      padding: 'md',
    },
  },
})
