/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const Ratings = helpers.defineMultiStyleConfig({
  baseStyle: {
    ratingWrapper: {
      alignItems: 'center',
    },
    ratingTextWrapper: {
      marginLeft: '2xs',
    },
    ratingStar: {
      mr: '4xs',
    },
  },
})
