import { DeepPartial, fitArrayForTuple, NonEmptyArray } from '@reward-platform/tsutil'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { countries, Country, stateOrProvinceMap } from '@reward-platform/utils'
import { RefinementCtx, z, ZodIssueCode } from 'zod'
import { format, parseISO } from 'date-fns'
import { Basket } from '../basket'
import { AgeGroupSchema, DateSchema } from '../common'
import {
  BasketTraveler,
  getMainAndAdditionalTravelersByBasket,
  isHotelOrFlightTraveler,
} from '../helpers'
import { eighteenOrOlder, isDateOfBirthValid } from './is-date-of-birth-valid'

export const TitleSchema = z.enum(['Ms', 'Mr', 'Miss', 'Mrs'])

const validUSZipCode = /^\d{5}(-\d{4})?$/i
const isValidCAPostalCode = /^[a-z]\d[a-z] \d[a-z]\d$/i
const isValidOtherPostalCode = /^[a-z0-9 ]{4,9}$/i

export const FIRST_NAME_MIN_LENGTH = 2
export const LAST_NAME_MIN_LENGTH = 2

const parsePostcode = (
  country: Country['value'],
  postcode: string
): { parsed: string | undefined; isValid: boolean } => {
  const trimmedPostcode = postcode.trim().replaceAll(/ +/g, ' ')
  switch (country) {
    case undefined:
      return { parsed: trimmedPostcode, isValid: true }
    case 'US':
      return { parsed: trimmedPostcode, isValid: validUSZipCode.test(trimmedPostcode) }
    case 'CA':
      return { parsed: trimmedPostcode, isValid: isValidCAPostalCode.test(trimmedPostcode) }
    default:
      return { parsed: trimmedPostcode, isValid: isValidOtherPostalCode.test(trimmedPostcode) }
  }
}

const isValidStateOrProvince = (country: Country['value'], stateOrProvince: string | undefined) => {
  if (!country || !stateOrProvinceMap || !stateOrProvinceMap[country]) {
    return true
  }
  return stateOrProvinceMap[country].find(({ value }) => value === stateOrProvince) ?? false
}

export const travelerInformationAddressFields = z.object({
  addressLineOne: z.string().trim().min(1),
  addressLineTwo: z.string().trim().optional(),
  city: z.string().trim().min(1),
  stateOrProvince: z.string().trim().optional(),
  postcode: z.string().trim().min(1),
  country: z.enum(countries.map(({ value }) => value) as NonEmptyArray<Country['value']>),
})

export const TravelerInformationAddressSchema = z.object({
  address: travelerInformationAddressFields
    .superRefine((val, ctx) => {
      const { country, postcode, stateOrProvince } = val
      if (!isValidStateOrProvince(country, stateOrProvince)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['stateOrProvince'],
        })
      }

      const { isValid: isValidPostcode } = parsePostcode(country, postcode)
      if (!isValidPostcode) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['postcode'],
        })
      }
    })
    .transform((val) => ({ ...val, postcode: parsePostcode(val.country, val.postcode).parsed })),
})

export type TravelerInformationAddress = z.infer<typeof TravelerInformationAddressSchema>

const getTravelerAgeField = (traveler: BasketTraveler) => {
  if (isHotelOrFlightTraveler(traveler)) {
    const { age, ageGroup } = traveler

    if (age == null && AgeGroupSchema.Enum.ADULT !== ageGroup) {
      throw new Error('Age cannot be empty for a non-adult traveler')
    }

    return traveler.age
  }

  return undefined
}

export const createTravelerInformationIdSchema = (traveler: BasketTraveler) => {
  const { id, ageGroup } = traveler
  const age = getTravelerAgeField(traveler)

  const ageSchema =
    age != null ? z.preprocess((val) => Number(val), z.literal(age)) : z.undefined().optional()

  return z.object({
    id: z.literal(id),
    ageGroup: z.literal(ageGroup),
    age: ageSchema,
  })
}

export type TravelerInformationId = z.infer<ReturnType<typeof createTravelerInformationIdSchema>>

export type CreateTravelerInformationNameSchemaParams = {
  isTitleRequired?: boolean
  isNameRequired?: boolean
  isDateOfBirthRequired?: boolean
  isGenderRequired?: boolean
}

export const GenderSchema = z.enum(['FEMALE', 'MALE'])

export type Gender = z.infer<typeof GenderSchema>

export const DateOfBirthSchema = z.preprocess(
  (arg) => {
    if (arg instanceof Date) {
      return new Date(arg.getTime() + arg.getTimezoneOffset() * 60 * 1000)
    }

    if (typeof arg === 'string') {
      return parseISO(arg)
    }

    return arg
  },
  DateSchema.transform((dob) => format(dob, 'yyyy-MM-dd'))
)

export const createTravelerInformationNameSchema = (
  params: CreateTravelerInformationNameSchemaParams = {
    isNameRequired: true,
    isTitleRequired: false,
    isDateOfBirthRequired: false,
    isGenderRequired: false,
  }
) => {
  const { isTitleRequired, isDateOfBirthRequired, isGenderRequired, isNameRequired } = params

  const title = z.preprocess((val) => {
    if (typeof val === 'string' && !val.trim().length) {
      return undefined
    }
    return val
  }, TitleSchema.or(z.string().trim()))

  const dateOfBirth = DateOfBirthSchema
  const genderSchema = isGenderRequired ? GenderSchema : GenderSchema.optional()

  const gender = z.preprocess((arg) => {
    if (typeof arg === 'string' && arg.trim().length === 0) {
      return undefined
    }

    return arg
  }, genderSchema)

  const firstNameSchema = z.string().trim().min(FIRST_NAME_MIN_LENGTH)
  const lastNameSchema = z.string().trim().min(LAST_NAME_MIN_LENGTH)

  return z.object({
    title: isTitleRequired ? title : title.optional(),
    firstName: isNameRequired ? firstNameSchema : firstNameSchema.optional(),
    familyName: isNameRequired ? lastNameSchema : lastNameSchema.optional(),
    dateOfBirth: isDateOfBirthRequired ? dateOfBirth : dateOfBirth.optional(),
    gender: isGenderRequired ? gender : gender.optional(),
  })
}

export type TravelerInformationName = z.infer<
  ReturnType<typeof createTravelerInformationNameSchema>
>

const travelerEmailRegex =
  /^(?=.{1,64}@)[A-Za-z0-9+_-]+(.[A-Za-z0-9+_-]+)*@[^-][A-Za-z0-9-+]+(.[A-Za-z0-9-+]+)*(.[A-Za-z]{2,})$/

export const TravelerInformationContactSchema = z.object({
  emailAddress: z.string().trim().min(1).regex(travelerEmailRegex),
  phone: z.string().trim().min(1).refine(isValidPhoneNumber),
})

const mainTravelerEmailRegex =
  /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@(?=.{1,255}$)[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]+)$/

export const MainTravelerInformationContactSchema = z.object({
  emailAddress: z.string().trim().min(1).regex(mainTravelerEmailRegex),
  phone: z.string().trim().min(1).refine(isValidPhoneNumber),
})

export type TravelerInformationContact = z.infer<typeof TravelerInformationContactSchema>

export const TravelerIsAccountHolderSchema = z.object({
  isAccountHolder: z.boolean().default(false).optional(),
})

export type TravelerIsAccountHolder = z.infer<typeof TravelerIsAccountHolderSchema>

export type MainTravelerDetails = TravelerInformationId &
  TravelerIsAccountHolder &
  TravelerInformationName &
  DeepPartial<TravelerInformationAddress> &
  Partial<TravelerInformationContact>

export type AdditionalTravelerDetails = TravelerInformationId & TravelerInformationName

export type CreateTravelerInformationSchemaParams = {
  basket: Basket
  collectNamesForAllHotelTravelers: boolean
  throwErrorFailure?: boolean
}

type DateOfBirthValidatorParams = {
  basket: Basket
  traveler: BasketTraveler
  dateOfBirth?: string
  ctx: RefinementCtx
}

const dateOfBirthValidator = ({
  basket,
  traveler,
  dateOfBirth,
  ctx,
}: DateOfBirthValidatorParams) => {
  if (isHotelOrFlightTraveler(traveler) && traveler.collectDateOfBirth && dateOfBirth != null) {
    const expectedAge = traveler.age ?? eighteenOrOlder
    const { isValid, actual, expected } = isDateOfBirthValid({ basket, dateOfBirth, expectedAge })
    if (!isValid) {
      if (expectedAge === eighteenOrOlder) {
        ctx.addIssue({
          code: ZodIssueCode.too_small,
          inclusive: true,
          minimum: 18,
          type: 'date',
          message: `The traveller must be 18 years or older, actual: ${actual}`,
          path: ['dateOfBirth'],
        })
      } else {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: `Invalid date of birth, expected: ${expected}, actual: ${actual}`,
          path: ['dateOfBirth'],
        })
      }
    }
  }
}

export const createTravelerInformationSchemaByBasket = ({
  basket,
  collectNamesForAllHotelTravelers,
}: CreateTravelerInformationSchemaParams) => {
  if (
    !basket.items.HOTEL?.length &&
    !basket.items.FLIGHT?.length &&
    !basket.items.EXPERIENCE?.length
  ) {
    throw new Error('No flight, hotel, or experience basket item defined')
  }

  const travelers = getMainAndAdditionalTravelersByBasket({
    basket,
    collectNamesForAllHotelTravelers,
  })

  if (!travelers) {
    throw new Error('Unable to determine travelers')
  }

  const { mainTraveler, additionalTravelers } = travelers

  return z.object({
    accountHolderEmailAddress: z.string().email(),
    mainTraveler: z
      .object({
        isAccountHolder: z.boolean().default(false).optional(),
      })
      .and(createTravelerInformationIdSchema(mainTraveler))
      .and(
        createTravelerInformationNameSchema({
          isTitleRequired: mainTraveler.isTitleRequired,
          isDateOfBirthRequired: mainTraveler.collectDateOfBirth,
          isGenderRequired: mainTraveler.collectGender,
        }).superRefine(({ dateOfBirth }, ctx) =>
          dateOfBirthValidator({ basket, traveler: mainTraveler, dateOfBirth, ctx })
        )
      )
      .and(MainTravelerInformationContactSchema)
      .and(TravelerInformationAddressSchema),
    additionalTravelers:
      additionalTravelers.length > 0
        ? z.preprocess(
            (val) => {
              const unorderedTravelers = val as AdditionalTravelerDetails[]

              const orderedIds = additionalTravelers.map(({ id }) => id)

              return unorderedTravelers
                .slice()
                .sort((a, b) => orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id))
            },
            z.tuple(
              fitArrayForTuple(
                additionalTravelers.map((traveler) =>
                  createTravelerInformationIdSchema(traveler)
                    .and(
                      createTravelerInformationNameSchema({
                        isTitleRequired: traveler.isTitleRequired,
                        isNameRequired: traveler.collectName,
                        isDateOfBirthRequired: traveler.collectDateOfBirth,
                        isGenderRequired: traveler.collectGender,
                      })
                    )
                    .superRefine(({ dateOfBirth }, ctx) => {
                      dateOfBirthValidator({ basket, traveler, dateOfBirth, ctx })
                    })
                )
              )
            )
          )
        : z.undefined(),
  })
}

export const safeCreateTravelerInformationSchemaByBasket = (
  params: CreateTravelerInformationSchemaParams
) => {
  try {
    return createTravelerInformationSchemaByBasket(params)
  } catch (error) {
    return undefined
  }
}

export type TravelerInformation = z.infer<
  ReturnType<typeof createTravelerInformationSchemaByBasket>
>
