/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const backgroundColor = 'rgba(44, 42, 41, 0.7)'

export const Carousel = helpers.defineMultiStyleConfig({
  baseStyle: {
    navigationButton: {
      height: 'var(--alto-sem-space-xl)',
      width: 'var(--alto-sem-space-xl)',
      backgroundColor,

      _hover: {
        backgroundColor,
      },
      _focus: {
        backgroundColor,
      },
      _active: {
        backgroundColor,
      },
    },
    navigationIcon: {
      boxSize: 3,
    },
  },
})
