/* eslint-disable sonarjs/no-duplicate-string */
import { tooltipHelper } from './base'

const vibrantDefault = 'bg.inverse.vibrant.default'
const white = 'bg.base'
const boxShadow = 'brand.offsetShadow'

export const Tooltip = tooltipHelper.defineMultiStyleConfig({
  baseStyle: tooltipHelper.definePartsStyle({
    containerBg: {
      bg: white,
    },
    containerBorder: {
      border: 'var(--alto-sem-border-width-sm) solid',
      borderColor: vibrantDefault,
    },
    wrapper: {
      '> div': {
        p: {
          fontSize: 'var(--alto-sem-text-body-sm-font-size)',
        },
      },
      '> button': {
        border: 'var(--alto-sem-border-width-sm) solid',
        borderColor: vibrantDefault,
        color: vibrantDefault,
        borderRadius: 'var(--alto-sem-radius-sm)',
        background: white,
        _hover: {
          background: white,
          boxShadow,
        },
      },
    },
  }),
})
