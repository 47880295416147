import { Basket } from '../basket'
import { AgeGroup, AgeGroupSchema } from '../common'

type BaseTraveler = {
  id: string
  ageGroup: AgeGroup
} & (
  | {
      // If collectName is false, we shouldn't collect any other details either
      collectName: false
      isTitleRequired: false
      collectDateOfBirth: false
      collectGender: false
    }
  | {
      // If collectName is true, we might collect some other details
      collectName: true
      isTitleRequired: boolean
      collectDateOfBirth: boolean
      collectGender: boolean
    }
)

export enum TravelerType {
  FLIGHT_TRAVELER = 'FLIGHT_TRAVELER',
  HOTEL_TRAVELER = 'HOTEL_TRAVELER',
  EXPERIENCE_TRAVELER = 'EXPERIENCE_TRAVELER',
}

export type FlightTraveler = BaseTraveler & {
  type: TravelerType.FLIGHT_TRAVELER
  age?: number
}

export type HotelTraveler = BaseTraveler & {
  type: TravelerType.HOTEL_TRAVELER
  age?: number
}

export type ExperienceTraveler = BaseTraveler & {
  type: TravelerType.EXPERIENCE_TRAVELER
  startAge?: number
  endAge?: number
}

export type BasketTraveler = FlightTraveler | HotelTraveler | ExperienceTraveler

export const isFlightTraveler = (traveler: BasketTraveler): traveler is FlightTraveler =>
  traveler.type === TravelerType.FLIGHT_TRAVELER

export const isHotelTraveler = (traveler: BasketTraveler): traveler is HotelTraveler =>
  traveler.type === TravelerType.HOTEL_TRAVELER

export const isHotelOrFlightTraveler = (
  traveler: BasketTraveler
): traveler is HotelTraveler | FlightTraveler =>
  isHotelTraveler(traveler) || isFlightTraveler(traveler)

export const isExperienceTraveler = (traveler: BasketTraveler): traveler is ExperienceTraveler =>
  traveler.type === TravelerType.EXPERIENCE_TRAVELER

const getTravelers = (
  basket: Basket,
  collectNamesForAllHotelTravelers: boolean
): BasketTraveler[] => {
  const flightItem = basket.items.FLIGHT?.at(0)
  if (flightItem) {
    return flightItem.travelers.map((traveler) => ({
      ...traveler,
      type: TravelerType.FLIGHT_TRAVELER,
      collectName: true,
      collectDateOfBirth: flightItem.isSecure || (traveler.age != null && traveler.age <= 16),
      collectGender: Boolean(flightItem.isSecure),
      isTitleRequired: true,
    }))
  }

  const hotelItem = basket.items.HOTEL?.at(0)
  if (hotelItem) {
    return hotelItem.travelers.flatMap((group) => {
      // Find the first adult in the room and collect the name only for them
      const firstAdultInRoomIndex = group.findIndex(
        (traveler) => traveler.ageGroup === AgeGroupSchema.Enum.ADULT
      )

      return group.map((traveler, index) => ({
        ...traveler,
        type: TravelerType.HOTEL_TRAVELER,
        collectName: collectNamesForAllHotelTravelers || firstAdultInRoomIndex === index,
        collectDateOfBirth: false,
        collectGender: false,
        isTitleRequired: false,
      }))
    })
  }

  const experienceItem = basket.items.EXPERIENCE?.at(0)
  if (experienceItem) {
    return experienceItem.travelers
      .map(({ ageGroup, startAge, endAge, quantity }, i) =>
        Array.from({ length: quantity }).map((_, j) => ({
          id: `${i}-${j}`,
          ageGroup,
          startAge,
          endAge,
          type: TravelerType.EXPERIENCE_TRAVELER,
          collectName: true as const,
          collectDateOfBirth: false,
          collectGender: false,
          isTitleRequired: false,
        }))
      )
      .flat()
  }

  return []
}

export const getTravelersByBasketItems = (
  basket: Basket,
  collectNamesForAllHotelTravelers: boolean
): BasketTraveler[] => {
  const sortOrder: AgeGroup[] = [
    AgeGroupSchema.Enum.ADULT,
    AgeGroupSchema.Enum.SENIOR,
    AgeGroupSchema.Enum.YOUTH,
    AgeGroupSchema.Enum.TRAVELER,
    AgeGroupSchema.Enum.CHILD,
    AgeGroupSchema.Enum.INFANT,
  ]

  return getTravelers(basket, collectNamesForAllHotelTravelers).sort(
    ({ ageGroup: ageGroupA }, { ageGroup: ageGroupB }) =>
      sortOrder.indexOf(ageGroupA) - sortOrder.indexOf(ageGroupB)
  )
}

type GetMainAndAdditionalTravelersByBasketParams = {
  basket: Basket
  collectNamesForAllHotelTravelers: boolean
  throwIfMainTravelerDoesNotExist?: boolean
}

export const getMainAndAdditionalTravelersByBasket = ({
  basket,
  collectNamesForAllHotelTravelers,
  throwIfMainTravelerDoesNotExist = true,
}: GetMainAndAdditionalTravelersByBasketParams) => {
  const basketTravelers = getTravelersByBasketItems(basket, collectNamesForAllHotelTravelers)
  const mainTraveler = basketTravelers.find((traveler) => {
    if (isHotelOrFlightTraveler(traveler)) {
      const { ageGroup, age } = traveler
      return ageGroup === AgeGroupSchema.Enum.ADULT && age === undefined
    }

    if (isExperienceTraveler(traveler)) {
      const mainTravelerAgeGroups: AgeGroup[] = [
        AgeGroupSchema.Enum.ADULT,
        AgeGroupSchema.Enum.SENIOR,
        AgeGroupSchema.Enum.YOUTH,
        AgeGroupSchema.Enum.TRAVELER,
      ]

      return mainTravelerAgeGroups.includes(traveler.ageGroup)
    }

    return undefined
  })

  if (!mainTraveler) {
    if (throwIfMainTravelerDoesNotExist) {
      throw new Error('Could not find main traveler')
    } else {
      return undefined
    }
  }

  const additionalTravelers = basketTravelers.filter(({ id }) => id !== mainTraveler.id) ?? []

  return { mainTraveler, additionalTravelers }
}
