/* eslint-disable sonarjs/no-duplicate-string */
import {
  commonText,
  variantExperimentText,
} from '~/components/shared/GuestRatings/themes/britishAirways'
import { helpers } from './base'

export const GuestRatings = helpers.defineMultiStyleConfig({
  baseStyle: {
    text: {
      color: 'fg.primary',
      ...commonText,
    },
  },
  variants: {
    experiment: {
      text: {
        ...variantExperimentText,
        fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      },
      reviewsCount: {
        fontWeight: 'light',
      },
      guestRating: {
        backgroundColor: 'bg.accent.subtle.default',
        padding: 'var(--alto-sem-space-2xs) var(--alto-sem-space-sm)',
        border: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-accent)',
        borderRadius: 'sm',
        marginRight: '2xs',
      },
      icon: {
        marginBottom: 'xs',
        marginRight: '2xs',
        '> path': {
          fill: 'fg.success.primary',
        },
      },
      rightChevronIcon: {
        paddingLeft: '2xs',
      },
    },
  },
})
