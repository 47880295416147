/* eslint-disable sonarjs/no-duplicate-string */
import { carItemExtrasPopoverHelper } from './base'

export const CarItemExtrasPopover = carItemExtrasPopoverHelper.defineMultiStyleConfig({
  baseStyle: carItemExtrasPopoverHelper.definePartsStyle({
    extrasIcon: {
      backgroundColor: 'bg.accent.subtle.default',
    },
    carExtraWrapper: {
      '> div > section > div': {
        borderRadius: 'sm',
      },
      li: {
        bg: 'bg.layer1.default',
        borderRadius: 'md',
      },
    },
  }),
})
