import React from 'react'
import Image from 'next/image'
import { getPartnerLogoUrl } from '~/utils/getPartnerAssets'
import { isProduction } from '~/utils/envChecks'
import logo from './logo.png'
import { ErrorBoundaryPageProps } from './ErrorBoundary.types'

const ErrorPage = ({ partner, error, reset }: ErrorBoundaryPageProps) => {
  const siteLogo = partner ? getPartnerLogoUrl(partner) : logo
  const isProd = isProduction()

  return (
    <div style={{ display: 'flex', width: '80%', margin: '20% auto' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Image
          className="logo"
          alt="logo"
          src={siteLogo}
          width={200}
          height={70}
          style={{ padding: '2rem' }}
        />
        <div>
          <h1 style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ paddingRight: '1rem' }}>{String.fromCharCode(9888)}</span>
            Site is down for maintenance
          </h1>
          <h2>We are working hard to be up and running soon, please try again later.</h2>

          {!isProd ? (
            <div role="alert">
              <h2>Developer Message.</h2>
              <p>More details in available DataDog</p>
              <details>{error.message}</details>
              {reset ? (
                <button type="button" onClick={reset}>
                  X
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <script src="" async defer />
    </div>
  )
}

export default ErrorPage
