import React, { createContext, useContext, useMemo, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
// Services
import { useAuthToken } from '@reward-platform/auth'
import { RetrieveMemberTypesMember } from '@reward-platform/members-api-client'
import { useNotification } from '~/components/shared/Notifications'
import { getMyAccounts, selectTotalAccount } from '~/services/accountsService'
import { bffClient, setCountryHeader, setCurrencyHeader } from '~/services/clients/bffClient'
import { getMe } from '~/services/membersService'
import useCorrelationId from '~/hooks/useCorrelationId/useCorrelationId'
import { datadogRum } from '~/utils/datadog'
import useChatWidget from '~/hooks/useChatWidget/useChatWidget'
import { PlatformError } from '~/utils/errors'
// Types
import { UserContextValue } from './User.types'

const USER_KEY = 'getUser'
const ACCOUNT_KEY = 'getAccount'

export const DEFAULT_LOCALE = 'en-GB'

const DEFAULT_USER: UserContextValue = {
  userCurrency: 'GBP',
  userCountry: 'GB',
  userLocale: DEFAULT_LOCALE,
  isDefault: true,
}

export const UserContext = createContext<UserContextValue | undefined>(DEFAULT_USER)

UserContext.displayName = 'UserContext'

export function useUserQuery() {
  const { hasAccessToken } = useAuthToken()
  return useQuery({
    queryKey: [USER_KEY],
    queryFn: () => getMe(),
    enabled: hasAccessToken,
    meta: {
      notifyUser: false,
      errorMessage: 'Members Error - could not get member details',
    },
  })
}

export function useAccountQuery() {
  const { hasAccessToken } = useAuthToken()
  return useQuery({
    queryKey: [ACCOUNT_KEY],
    queryFn: () => getMyAccounts(),
    enabled: hasAccessToken,
    meta: {
      notifyUser: false,
      errorMessage: 'Accounts Error - could not get account details',
    },
  })
}

export const resolveUserLocale = (
  userData: RetrieveMemberTypesMember | undefined,
  defaultValue?: string
) => {
  if (userData?.person?.locale) {
    if (userData.person.locale.length > 2) {
      return userData.person.locale
    }
    if (
      userData.person.locale.toLowerCase() === 'en' &&
      userData.person.countryOfResidence?.identifier.toLowerCase() === 'us'
    ) {
      return 'en-US'
    }
  }

  return defaultValue || DEFAULT_LOCALE
}

export const UserProvider = ({ children }: React.PropsWithChildren): React.ReactElement => {
  const [userLocale, setUserLocale] = useState<string>(DEFAULT_LOCALE)
  const { addNotification } = useNotification()
  const queryClient = useQueryClient()
  const { correlationId } = useCorrelationId()

  const [headersSet, setHeadersSet] = useState(
    !!(
      bffClient.defaults.headers.common['x-country-code'] &&
      bffClient.defaults.headers.common['x-currency-code']
    )
  )

  const getErrorMessage = (error: unknown) => {
    if (error instanceof Error) {
      return error.message
    }
    return String(error)
  }

  const {
    data: userData,
    isError: userDataIsError,
    error: userDataError,
    isLoading: userDataIsLoading,
  } = useUserQuery()

  const {
    data: accountData,
    isError: accountDataIsError,
    error: accountDataError,
    isLoading: accountDataIsLoading,
  } = useAccountQuery()

  const currency = userData?.person?.currencyCode ?? DEFAULT_USER.userCurrency
  const country = userData?.person?.countryOfResidence?.identifier ?? DEFAULT_USER.userCountry

  useEffect(() => {
    const locale = resolveUserLocale(userData, DEFAULT_LOCALE)
    if (locale) {
      setUserLocale(locale)
    }
  }, [userData])

  const { memberIdentifier, person } = userData || {}

  useChatWidget(memberIdentifier?.identifier, person)

  useEffect(() => {
    if (memberIdentifier?.identifier) {
      datadogRum.setUser({
        id: memberIdentifier.identifier,
        correlationId,
      })
    }
  }, [correlationId, memberIdentifier?.identifier])

  useEffect(() => {
    if (userDataError) {
      addNotification(getErrorMessage(userDataError), 'ERROR', 'RELOAD')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataError])

  useEffect(() => {
    if (accountDataError) {
      addNotification(getErrorMessage(accountDataError), 'ERROR', 'RELOAD')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDataError])

  useEffect(() => {
    if (country) {
      setCountryHeader(country)
    }
    if (currency) {
      setCurrencyHeader(currency)
    }
    if (currency && country) {
      setHeadersSet(true)
    }
  }, [currency, country])

  const contextValue = useMemo<UserContextValue>(
    () => ({
      user: headersSet ? userData : undefined,
      invalidateUser: () => queryClient.invalidateQueries({ queryKey: [USER_KEY] }),
      userDataIsError,
      userDataIsLoading,
      userDataError,
      account: accountData && selectTotalAccount(accountData),
      invalidateAccount: () => queryClient.invalidateQueries({ queryKey: [ACCOUNT_KEY] }),
      accountDataIsError,
      accountDataIsLoading,
      accountDataError,
      userLocale,
      userCurrency: currency,
      userCountry: country,
      isDefault: false,
    }),
    [
      userData,
      userDataIsError,
      userDataIsLoading,
      userDataError,
      accountData,
      accountDataIsError,
      accountDataIsLoading,
      accountDataError,
      userLocale,
      queryClient,
      country,
      currency,
      headersSet,
    ]
  )

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}

export function useUser(): UserContextValue {
  const context = useContext(UserContext)
  if (!context) {
    throw new PlatformError('useUser must be used within a UserProvider')
  }
  return context ?? {}
}

export function useIsAuthenticatedUser(): boolean {
  const user = useContext(UserContext)
  return !user?.isDefault
}
