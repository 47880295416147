import { css } from '@emotion/react'

const typography = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--alto-sem-text-heading-font-family);
    font-weight: var(--alto-sem-text-heading-font-weight);
  }

  h1 {
    font-size: var(--alto-sem-text-heading-xl-font-size);
    line-height: var(--alto-sem-text-heading-xl-line-height);
    letter-spacing: var(--alto-sem-text-heading-xl-letter-spacing);
  }

  h2 {
    font-size: var(--alto-sem-text-heading-lg-font-size);
    line-height: var(--alto-sem-text-heading-lg-line-height);
    letter-spacing: var(--alto-sem-text-heading-lg-letter-spacing);
  }

  h3 {
    font-size: var(--alto-sem-text-heading-md-font-size);
    line-height: var(--alto-sem-text-heading-md-line-height);
    letter-spacing: var(--alto-sem-text-heading-md-letter-spacing);
  }

  h4 {
    font-size: var(--alto-sem-text-heading-sm-font-size);
    line-height: var(--alto-sem-text-heading-sm-line-height);
    letter-spacing: var(--alto-sem-text-heading-sm-letter-spacing);
  }

  h5 {
    font-size: var(--alto-sem-text-heading-xs-font-size);
    line-height: var(--alto-sem-text-heading-xs-line-height);
    letter-spacing: var(--alto-sem-text-heading-xs-letter-spacing);
  }

  h5 {
    font-size: var(--alto-sem-text-heading-xs-font-size);
    line-height: var(--alto-sem-text-heading-xs-line-height);
    letter-spacing: var(--alto-sem-text-heading-xs-letter-spacing);
  }

  h6 {
    font-size: var(--alto-sem-text-body-md-font-size);
    line-height: var(--alto-sem-text-heading-xs-line-height);
    letter-spacing: var(--alto-sem-text-heading-xs-letter-spacing);
  }

  body {
    font-family: var(--alto-sem-text-body-font-family);
    font-weight: var(--alto-sem-text-body-regular-font-weight);
    font-size: var(--alto-sem-text-body-md-font-size);
    color: var(--alto-sem-color-fg-primary);
  }

  strong {
    font-weight: var(--alto-sem-text-body-bold-font-weight);
  }

  a {
    font-weight: var(--alto-sem-text-body-link-font-weight);
    text-decoration: var(--alto-sem-text-body-link-text-decoration);
  }
`

export default typography
