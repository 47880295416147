/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const accentPrimary = 'fg.accent.primary'

export const CarAdditionalProductCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      border: 'none',
    },
    heading: {
      color: accentPrimary,
      fontWeight: 'light',
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      lineHeight: {
        base: 'var(--alto-sem-text-heading-2xs-line-height)',
        lg: 'var(--alto-sem-text-heading-md-line-height)',
      },
    },
    duration: {
      color: accentPrimary,
      fontWeight: 'light',
      fontSize: 'var(--alto-sem-text-body-xs-font-size)',
    },
    description: {
      color: accentPrimary,
      fontWeight: 'light',
      fontSize: 'var(--alto-sem-text-body-md-font-size)',
    },
    button: {
      border: 'var(--alto-sem-border-width-sm) solid',
      _hover: {
        borderColor: accentPrimary,
        bg: accentPrimary,
      },
    },
  },
})
