/* eslint-disable sonarjs/no-duplicate-string */
import { pricingHelpers } from './base'

const fgPrimary = 'fg.primary'
const xsFontSize = 'var(--alto-sem-text-heading-xs-font-size)'

export const Pricing = pricingHelpers.defineMultiStyleConfig({
  baseStyle: {
    headerLabel: {
      fontWeight: 'light',
      color: fgPrimary,
    },
    price: {
      fontSize: {
        base: xsFontSize,
        md: 'var(--alto-sem-text-heading-md-font-size)',
      },
      color: fgPrimary,
      fontWeight: 'light',
      margin: 0,
      padding: 0,
    },
    priceNote: {
      color: fgPrimary,
      strong: { fontWeight: 'semibold' },
    },
    expandButton: {
      color: fgPrimary,
      bgColor: 'bg.base',
    },
    pricingOptionsBefore: {
      borderRadius: 'xs',
      backgroundColor: 'bg.layer1.default',
    },
    pricingOptionsList: {
      color: fgPrimary,
    },
    originalPrice: {
      color: 'fg.critical.primary',
    },
  },
  variants: {
    mapOpen: {
      price: {
        fontSize: xsFontSize,
      },
    },
  },
  sizes: {
    small: {
      price: {
        fontSize: xsFontSize,
      },
    },
  },
})
