import { Partner } from '~/services/partnerService'
import { getPublicAssetSrc } from './addBasePathToPath'

export const getPartnerFilePrefix = (partner: Partner) =>
  partner.name.toLocaleLowerCase().replace(' ', '-')

export const getPartnerLogoUrl = (partner: Partner, shouldUseMobileLogo?: boolean) => {
  const partnerName = getPartnerFilePrefix(partner)
  const filenameSuffix = shouldUseMobileLogo ? 'logo-mobile' : 'logo'
  const filePath = `/assets/${partnerName}-${filenameSuffix}.svg`
  return getPublicAssetSrc(filePath)
}

export const getPartnerIconUrl = (partner?: Partner) =>
  partner ? getPublicAssetSrc(`/assets/favicon/${partner.theme.toUpperCase()}.svg`) : undefined

export const getPartnerFallbackImageUrl = (partner: Partner) =>
  getPublicAssetSrc(`/assets/default-brand-images/${partner.theme.toLowerCase()}.svg`)
