import { defineStyleConfig } from '@chakra-ui/react'

export const PriceBreakdownCollectedAviosAmount = defineStyleConfig({
  baseStyle: {
    fontWeight: 'semibold',
    bg: 'bg.base',
    px: '3xs',
    py: '2xs',
    borderRadius: '3xs',
    border: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-accent)',
  },
})
