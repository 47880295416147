import { basketItemCardHelpers } from './base'

export const BasketItemCard = basketItemCardHelpers.defineMultiStyleConfig({
  baseStyle: {
    cardWrapper: {
      borderRadius: 'xs',
      bg: 'bg.layer1.default',
    },
  },
})
