/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const fgPrimary = 'fg.primary'

export const PopularAmenities = helpers.defineMultiStyleConfig({
  baseStyle: {
    iconWrapper: {
      color: fgPrimary,
    },
    amenityText: {
      color: fgPrimary,
      fontWeight: 'light',
    },
    amenitiesList: {
      color: 'fg.primary',
    },
  },
  variants: {
    results: {
      amenityText: {
        fontSize: {
          base: 'var(--alto-sem-text-body-xs-font-size)',
          xl: 'var(--alto-sem-text-body-sm-font-size)',
        },
      },
    },
  },
})
export const PopularAmenitiesList = helpers.defineMultiStyleConfig({
  baseStyle: {
    iconColor: {
      color: fgPrimary,
    },
  },
})
