import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const spaceBetween = 'space-between'

const baseLineStyles = {
  display: 'flex',
  justifyContent: spaceBetween,
}

export const priceBreakdownRowItemHelper = createMultiStyleConfigHelpers([
  'priceBreakdownRow',
  'priceBreakdownRowTitleLine',
  'priceBreakdownRowLine',
  'arrowDownRight',
  'priceBreakdownSurchargeButton',
])

export const PriceBreakdownRowItem = priceBreakdownRowItemHelper.defineMultiStyleConfig({
  baseStyle: priceBreakdownRowItemHelper.definePartsStyle({
    priceBreakdownRow: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 2,
      fontSize: 'sm',
    },
    priceBreakdownRowTitleLine: {
      ...baseLineStyles,
    },
    priceBreakdownRowLine: {
      '>div': {
        width: '100%',
        display: 'flex',
        justifyContent: spaceBetween,
      },
      button: {
        ...baseLineStyles,
        width: '100%',
        svg: {
          width: 3,
          marginLeft: 2,
        },
      },
    },
    priceBreakdownCollapsible: {
      button: {
        ...baseLineStyles,
        svg: {
          width: 3,
          marginLeft: 2,
        },
      },
    },
    priceBreakdownBox: {
      button: {
        display: 'flex',
        width: 'max-content',
        height: 'max-content',
        outline: 'none',
        justifyContent: 'center',
      },
    },
    feesBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: spaceBetween,
      '> span:first-of-type:first-letter': {
        textTransform: 'capitalize',
      },
      backgroundColor: 'brand.teal5',
      padding: '8px',
      rounded: 'md',
    },
    feesBoxAmount: {
      fontSize: 'md',
    },
  }),
})
