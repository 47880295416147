import { ReactElement, ReactNode, useEffect } from 'react'
import type { NextPage } from 'next'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import { ChakraProvider } from '@chakra-ui/react'
import {
  AuthenticatedOnly,
  AuthProvider,
  parseAuth0Config,
  UnauthenticatedPage,
} from '@reward-platform/auth'
import ReactModal from 'react-modal'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Usercentrics } from '~/components/shared/Scripts'
import Layout from '~/components/shared/Layout'
import { UnauthenticatedLayout } from '~/components/shared/Layout/Layout'
import { NotificationProvider } from '~/components/shared/Notifications'
import SpinnerOverlay from '~/components/shared/SpinnerOverlay'
import { LocalisationProvider } from '~/context/localisation'
import QueryClientProvider from '~/context/queryClient'
import { PartnerProvider } from '~/context/partner'
import { UserProvider } from '~/context/user'
import { PromotionProvider } from '~/context/promotion'
import { DevCycleProvider, IdentifyDevCycleUser } from '~/context/devcycle'
import useCorrelationId from '~/hooks/useCorrelationId/useCorrelationId'
import useHotjar from '~/hooks/useHotjar/useHotjar'
import useLastKnownUser from '~/hooks/useLastKnownUser/useLastKnownUser'
import { getPartner, Partner } from '~/services/partnerService'
import { useRouter } from 'next/router'
import { AppState } from '@auth0/auth0-react'
import { isClient } from '~/utils/envChecks'
import { chakraThemeMap } from '~/themes/chakra'
import useRemoveExpiredBasketItems from '~/hooks/useRemoveExpiredBasketItems'
import { ErrorBoundary } from '~/components/shared/ErrorBoundary'
import { NextPageError } from '~/utils/errors'
import GlobalStyles from '~/styles/globalStyles'
import { MaintenanceModeBoundary } from '../components/shared/MaintenanceModeBoundary'
import {
  setAuthorizationToken,
  setCorrelationId as setBFFCorrelationId,
  setPartner,
} from '../services/clients/bffClient'
import { initDatadogLogging, initDatadogRum } from '../utils/datadog'

import '../styles/global.css'

ReactModal.setAppElement('#__next')

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

interface MyAppProps extends AppProps {
  partner: Partner
  ip?: string
  Component: NextPageWithLayout
}

const DEFAULT_MESSAGE_CLUSTER = ['reward-platform']
const initialClusters =
  process.env.NEXT_PUBLIC_MESSAGE_CLUSTERS?.split(',') ?? DEFAULT_MESSAGE_CLUSTER

const ClearExpiredBasketItems = () => {
  useRemoveExpiredBasketItems()

  return null
}

function MyApp(props: MyAppProps): JSX.Element {
  const { Component, pageProps, partner } = props
  const registerUser = useLastKnownUser()
  const { correlationId, resetCorrelationId } = useCorrelationId()

  useHotjar()

  useEffect(() => {
    if (isClient()) {
      initDatadogRum()
      initDatadogLogging()
    }
  }, [])

  const auth0Config = parseAuth0Config(partner.code, process.env.NEXT_PUBLIC_BASE_PATH)

  useEffect(() => {
    setPartner(partner.code)
  }, [partner])

  useEffect(() => {
    if (correlationId == null) {
      resetCorrelationId()
      return
    }
    setBFFCorrelationId(correlationId)
  }, [correlationId, resetCorrelationId])

  const { replace } = useRouter()

  const onRedirectCallback = (appState?: AppState) => {
    replace(appState?.returnTo ?? '/')
  }

  const onTokenReceived = (token: string) => {
    registerUser(token)
    setAuthorizationToken(token)
  }

  const getLayout = Component.getLayout ?? ((page) => <Layout variant="search">{page}</Layout>)

  const getUnauthenticatedLayout =
    Component.getLayout ?? ((page) => <UnauthenticatedLayout>{page}</UnauthenticatedLayout>)

  return (
    <ErrorBoundary partner={partner}>
      <PartnerProvider partner={partner}>
        <DevCycleProvider partner={partner}>
          {({ isInitialized: isDevCycleInitialized }) => (
            <MaintenanceModeBoundary partner={partner}>
              <ChakraProvider theme={chakraThemeMap[partner.theme]} resetCSS={false}>
                <GlobalStyles partner={partner.theme} />
                <LocalisationProvider initialClusterKeys={initialClusters}>
                  <NotificationProvider>
                    <QueryClientProvider>
                      <IdentifyDevCycleUser />
                      <ClearExpiredBasketItems />
                      <Usercentrics partnerCode={partner.code} />
                      <AuthProvider {...auth0Config} onRedirectCallback={onRedirectCallback}>
                        {/* disabled in prod by default */}
                        <ReactQueryDevtools />
                        <AuthenticatedOnly
                          spinner={<SpinnerOverlay />}
                          onTokenReceived={onTokenReceived}
                          isLoading={!isDevCycleInitialized}
                        >
                          <UserProvider>
                            <PromotionProvider>
                              {getLayout(<Component {...pageProps} />)}
                            </PromotionProvider>
                          </UserProvider>
                        </AuthenticatedOnly>
                        <UnauthenticatedPage spinner={<SpinnerOverlay />}>
                          <PromotionProvider>
                            {getUnauthenticatedLayout(<Component {...pageProps} />)}
                          </PromotionProvider>
                        </UnauthenticatedPage>
                      </AuthProvider>
                    </QueryClientProvider>
                  </NotificationProvider>
                </LocalisationProvider>
              </ChakraProvider>
            </MaintenanceModeBoundary>
          )}
        </DevCycleProvider>
      </PartnerProvider>
    </ErrorBoundary>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  // nextjs's internal error page
  const { headers, socket } = appContext.ctx?.req ?? {}
  const partner = getPartner(headers?.host)
  const ipAddresses =
    headers?.['x-real-ip'] ?? headers?.['x-forwarded-for'] ?? socket?.remoteAddress
  const ip = Array.isArray(ipAddresses) ? ipAddresses.join() : ipAddresses

  // Handle error response
  if (appContext.ctx.err) {
    const error = new NextPageError(appContext.ctx.err)
    error.logToDatadog({ partner: partner.code })
  }

  const props = { ...appProps, partner, ip }
  if (appContext.router.pathname.includes('_error')) {
    return { ...props }
  }

  return { ...props }
}

export default MyApp
