import { Partner } from '~/services/partnerService'

import aviosTypographyStyles from './avios.typography'

import { PartnerStylesMap } from '../styles.types'

const typographyMap: PartnerStylesMap = {
  aer_lingus: null,
  vueling: null,
  british_airways: null,
  avios: aviosTypographyStyles,
}

export const getPartnerTypography = (partnerTheme: Partner['theme']) => typographyMap[partnerTheme]
