/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const CarDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    searchTitle: {
      color: 'fg.accent.primary',
    },
    pageTitle: {
      fontSize: 'var(--alto-sem-text-heading-xl-font-size)',
      fontWeight: 'light',
      lineHeight: 'short',
    },
    searchSummaryWrapper: {
      boxShadow: 'none',
    },
  },
})
