/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const RoomRateSelector = helpers.defineMultiStyleConfig({
  baseStyle: {
    optionContainerUnselected: {
      borderRadius: 'sm',
      overflow: 'hidden',
      border: 'var(--alto-sem-border-width-sm) solid',
      borderColor: 'border.primary.default',
      _disabled: {
        border: 'border.disabled.vibrant',
      },
    },
    optionContainerSelected: {
      borderRadius: 'sm',
      overflow: 'hidden',
      border: 'var(--alto-sem-border-width-md) solid',
      borderColor: 'border.accent',
      _disabled: {
        border: 'border.disabled.vibrant',
      },
    },
    optionIndicator: {
      m: '-1.5rem',
      background: 'bg.accent.vibrant.default',
      color: 'fg.accent.onVibrant',
    },
    soldOutOptionIndicator: {
      backgroundColor: 'bg.disabled.vibrant',
      color: 'fg.disabled.onVibrant',
    },
    inlineText: {
      fontSize: 'sm',
    },
    boldText: {
      fontSize: 'md',
    },
    semiboldText: {
      fontSize: 'sm',
    },
  },
})
