import { helpers } from './base'

export const LocationDetailsModalWrapper = helpers.defineMultiStyleConfig({
  baseStyle: {
    locationDetails: {
      h1: {
        fontSize: {
          md: 'var(--alto-sem-text-heading-lg-font-size)',
          base: 'var(--alto-sem-text-heading-md-font-size)',
        },
      },
      h2: {
        fontSize: {
          md: 'var(--alto-sem-text-heading-md-font-size)',
          base: 'var(--alto-sem-text-heading-sm-font-size)',
        },
      },
      h3: {
        fontSize: {
          md: 'var(--alto-sem-text-heading-sm-font-size)',
          base: 'var(--alto-sem-text-heading-xs-font-size)',
        },
      },
      'p, address, th, td': {
        color: 'fg.secondary',
      },
    },
  },
})
