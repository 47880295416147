import { helpers } from './base'

const fontXs = 'var(--alto-sem-text-body-xs-font-size)'

export const CarCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    carCardWrapper: {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'bg.layer2.default',
      borderRadius: 'sm',
    },
    heading: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      lineHeight: 'base',
    },
    name: {
      fontSize: fontXs,
      lineHeight: 'tall',
    },
    attributeListItem: {
      fontSize: fontXs,
      lineHeight: 'tall',
      fontWeight: 'light',
    },
    footerContainer: {
      gap: 'xs',
    },
    cancellationFeesAndPoliciesContainer: {
      h1: {
        fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
        my: { base: 'sm', lg: 'lg' },
      },
      strong: {
        fontWeight: 'light',
      },
      p: {
        fontSize: {
          base: fontXs,
          lg: 'var(--alto-sem-text-body-md-font-size)',
        },
      },
    },
    cancellationButton: {
      _hover: {
        border: 'none',
      },
      _focus: {
        border: 'none',
      },
      _focusVisible: {
        border: 'none',
      },
    },
    policyLink: {
      color: 'fg.accent.primary',
      fontSize: {
        base: 'var(--alto-sem-text-body-sm-font-size)',
        lg: 'var(--alto-sem-text-body-md-font-size)',
      },

      _hover: {
        textDecoration: 'none',
      },
    },
    duration: {
      fontSize: fontXs,
    },
    secondaryAttributeWrapper: {
      backgroundColor: 'bg.layer1.default',
    },

    fuelBadge: {
      fontSize: 'var(--alto-sem-text-body-sm-font-size)',
      fontWeight: 'normal',
      lineHeight: 5,
      backgroundColor: 'bg.accent.subtle.default',
      marginBottom: '2xs',
      borderRadius: '3xs',

      ' > div': {
        columnGap: 'xs',
      },

      svg: {
        width: 6,
        height: 6,
      },
    },

    electricBadge: {
      svg: {
        color: 'fg.success.primary',
      },
    },

    hybridBadge: {
      svg: {
        color: 'fg.information.onSubtle',
      },
    },
  },
})
