import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { merge } from 'lodash'
import { ImageCard as base } from './base'

export const ImageCard: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    figure: {
      bg: 'bg.layer2.default',
      borderRadius: 'xs',
      overflow: 'hidden',
      padding: 'var(--alto-sem-space-lg)',
      img: {
        borderRadius: 'xs',
      },
    },
    heading: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      fontWeight: 'medium',
      marginTop: 'var(--alto-sem-space-sm)',
    },
    caption: {
      fontSize: 'var(--alto-sem-text-body-md-font-size)',
      fontWeight: 'normal',
      marginTop: 'var(--alto-sem-space-sm)',
    },
  },
})
