/* eslint-disable sonarjs/no-duplicate-string */
import { replaceItemModalHelper } from './base'

export const ReplaceItemModal = replaceItemModalHelper.defineMultiStyleConfig({
  baseStyle: replaceItemModalHelper.definePartsStyle({
    optionHeading: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      fontWeight: 'light',
    },
    instructions: {
      p: {
        _first: {
          fontWeight: 'medium',
        },
      },
    },
    promptText: {
      color: 'fg.primary',
      fontSize: 'sm',
    },
    optionLinkWrapper: {
      _hover: {
        borderRadius: 'lg',
        bg: 'bg.layer1.default',
      },
      _focusWithin: {
        borderRadius: 'lg',
        bg: 'bg.layer1.default',
      },
    },
    buttonContainer: {
      button: {
        fontSize: 'md',
      },
    },
    button: {
      _first: {
        _hover: {
          backgroundColor: 'bg.base',
        },
      },
    },
  }),
})
