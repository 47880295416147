import { Global, css } from '@emotion/react'
import { Partner } from '~/services/partnerService'
import { getPartnerFontFaces } from './fonts'
import { getPartnerTypography } from './typography'
import { getPartnerVariables } from './variables'

type GlobalStyleProps = {
  partner: Partner['theme']
}

const GlobalPartnerStyles = ({ partner }: GlobalStyleProps) => {
  const variables = getPartnerVariables(partner)
  const fonts = getPartnerFontFaces(partner)
  const typography = getPartnerTypography(partner)

  return (
    <Global
      styles={css`
        ${variables}
        ${fonts}
        ${typography}
      `}
    />
  )
}

export default GlobalPartnerStyles
