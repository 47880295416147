import { helpers } from './base'

const white = 'bg.white.vibrant.default'

export const ListBox = helpers.defineMultiStyleConfig({
  baseStyle: {
    icon: {
      color: 'fg.primary',
    },
    list: {
      _focusVisible: {
        outline: '1px solid',
        outlineColor: 'var(--alto-sem-color-border-accent)',
      },
      border: 'none',
      borderRadius: '5xs',
      boxShadow: '0 6px 9px 0 rgb(0 0 0 / 10%)',
    },
    item: {
      fontWeight: 'light',
      _hover: {
        background: white,
      },
      _focus: {
        background: white,
      },
      _selected: {
        background: white,
      },
      _disabled: {
        color: 'bg.disabled.subtle',
      },
    },
  },
})
