import { helpers } from './base'

export const TravelerRoomPicker = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    maxTravellerBadge: {
      backgroundColor: 'bg.accent.subtle.default',
      fontSize: 'var(--alto-sem-text-body-md-font-size)',
      color: 'fg.secondary',
      borderRadius: '3xs',
      padding: 'var(--alto-sem-space-2xs) var(--alto-sem-space-xs)',
    },
  }),
})
