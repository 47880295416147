/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const ReplaceExperienceItemCard = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    content: {
      color: 'fg.primary',
      img: {
        borderRadius: 'lg',
      },
    },
    wrapper: {
      bg: 'bg.layer1.default',
    },
  }),
})
