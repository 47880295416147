/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const RoomsBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    heading: {
      fontWeight: 'medium',
      lineHeight: 'unset',
    },
    confirmButton: {
      fontWeight: 'medium',
      fontSize: 'sm',
      borderRadius: '3xl',
      background: 'transparent',
    },
    container: {
      borderRadius: 'lg',
      background: 'bg.layer2.default',
    },
  },
})
