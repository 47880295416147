/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const BasketItemSummary = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    basketItemSummaryWrapper: {
      bg: 'bg.accent.subtle.default',
      borderRadius: '6xs',
      '> p': {
        fontWeight: 'normal',
        fontSize: 'sm',
      },
    },
    content: {
      fontSize: 'xs',
    },
  }),
})
