import { helpers } from './base'

export const StickyBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      backgroundColor: 'bg.information.subtle.default',
    },
    icon: {
      color: 'fg.information.primary',
    },
    message: {
      color: 'fg.information.primary',
    },
  },
})
