import React, { ReactElement } from 'react'
import { chakra, useMultiStyleConfig } from '@chakra-ui/react'
import Image from '../ChakraNextImage/index'
import { ImageCardProps, ImageCardCaptionProps } from './ImageCard.interface'

function ImageCard({ children, href, ...props }: ImageCardProps): ReactElement {
  const styles = useMultiStyleConfig('ImageCard', {})

  const figContent = (
    <>
      <Image {...props} />
      <chakra.figcaption __css={styles.figcaption}>{children}</chakra.figcaption>
    </>
  )

  return (
    <chakra.figure __css={styles.figure}>
      {href ? (
        <chakra.a __css={styles.link} href={href}>
          {figContent}
        </chakra.a>
      ) : (
        figContent
      )}
    </chakra.figure>
  )
}

function ImageCardCaption({ children, heading, subHeading }: ImageCardCaptionProps): ReactElement {
  const styles = useMultiStyleConfig('ImageCard', {})

  return (
    <>
      {heading ? <chakra.h4 __css={styles.heading}>{heading}</chakra.h4> : null}
      {subHeading ? <chakra.h5 __css={styles.subHeading}>{subHeading}</chakra.h5> : null}
      {children ? <chakra.p __css={styles.caption}>{children}</chakra.p> : null}
    </>
  )
}

ImageCard.Caption = ImageCardCaption

export default ImageCard
