import { helpers } from './base'

export const ExperienceTitle = helpers.defineMultiStyleConfig({
  baseStyle: {
    experienceTitleContainer: {
      color: 'fg.primary',
    },
    ratingsAndChips: {
      columnGap: 'sm',
    },
    address: {
      paddingTop: 'md',
    },
    icons: {
      marginTop: 'md',
      columnGap: 'lg',
      rowGap: 'sm',
      color: 'fg.primary',
      '> div > span': {
        fontSize: 'var(--alto-sem-text-heading-2xs-font-size)',
        fontWeight: 'normal',
      },
    },
    experienceTitle: {
      fontWeight: 'normal',
      fontSize: 'var(--alto-sem-text-heading-xl-font-size)',
    },
  },
})
