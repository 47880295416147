import { helpers } from './base'

export const ExperienceInformation = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: 'sm',
      backgroundColor: 'bg.layer1.default',
      padding: 'lg',
    },
    title: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
    },
    description: {
      color: 'fg.primary',
      fontSize: 'md',
    },
  },
})
