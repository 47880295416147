import { basketTravellersLabelHelper } from './base'

export const BasketTravellersLabel = basketTravellersLabelHelper.defineMultiStyleConfig({
  baseStyle: {
    totalCountContainer: {
      'div div section': {
        fontWeight: 'semibold',
      },
    },
    ageGroupsContainer: {
      '> span': {
        fontWeight: 'semibold',
        bg: 'bg.accent.subtle.default',
        color: 'fg.accent.onSubtle',
        borderRadius: 'sm',
      },
      button: {
        bg: 'bg.accent.subtle.default',
        color: 'fg.accent.onSubtle',
        '> span': {
          fontWeight: 'semibold',
        },
      },
    },
  },
})
