import React from 'react'
import {
  chakra,
  Box,
  useMultiStyleConfig,
  SystemStyleObject,
  Text,
  Link,
  Image,
} from '@chakra-ui/react'
import {
  documentToReactComponents,
  Options,
  RenderNode,
} from '@contentful/rich-text-react-renderer'
import { INLINES, MARKS, BLOCKS, Node } from '@contentful/rich-text-types'
import { IRichTextProps } from './ChakraRichText.types'

const defaultOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text: React.ReactNode) => <chakra.strong>{text}</chakra.strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

const getHeadingNodes = (headingStyle: SystemStyleObject) => {
  const getProps = (children: React.ReactNode) => ({ children, sx: headingStyle })
  const options: Options['renderNode'] = {
    [BLOCKS.HEADING_1]: (node, children) => <chakra.h1 {...getProps(children)} />,
    [BLOCKS.HEADING_2]: (node, children) => <chakra.h2 {...getProps(children)} />,
    [BLOCKS.HEADING_3]: (node, children) => <chakra.h3 {...getProps(children)} />,
    [BLOCKS.HEADING_4]: (node, children) => <chakra.h4 {...getProps(children)} />,
  }
  return options
}

const getLinkNode = (linkStyle: SystemStyleObject, openLinksInNewTab?: boolean) => ({
  [INLINES.HYPERLINK]: (node: Node, children: React.ReactNode) => {
    const { data } = node
    const { uri } = data
    const additionalProps = openLinksInNewTab
      ? { target: '_blank', rel: 'noopener noreferrer' }
      : null
    return (
      <Link sx={linkStyle} href={uri} {...additionalProps}>
        {children}
      </Link>
    )
  },
})

const imageMimeTypeRegex = /([^\s]+(\/(jpe?g|png|gif|bmp))$)/
const isImage = (mimeType?: string) => mimeType && imageMimeTypeRegex.test(mimeType)

const getInlineAssetNodes = () => ({
  [BLOCKS.EMBEDDED_ASSET]: (node: Node, children: React.ReactNode) => {
    const {
      data: {
        target: { file, title },
      },
    } = node
    const { url, fileName, contentType, details } = file

    if (isImage(contentType)) {
      return <Image src={url} alt={fileName ?? title} {...details.image} />
    }

    return children
  },
})

const getInlineEntryNodes = (
  inlineEntryRenderer?: IRichTextProps['inlineEntryRenderer']
): RenderNode => {
  const options: RenderNode = {
    [INLINES.EMBEDDED_ENTRY]: (node, children) =>
      inlineEntryRenderer?.(node.data.target) || children,
  }
  return options
}

const RichText: React.FC<IRichTextProps> = ({
  document,
  openLinksInNewTab,
  inlineEntryRenderer,
  inlineTextEntryRenderer,
}) => {
  const styles = useMultiStyleConfig('RichText', {})
  const options: Options = {
    renderMark: {
      ...defaultOptions.renderMark,
    },
    renderNode: {
      ...defaultOptions.renderNode,
      ...getHeadingNodes(styles.heading),
      ...getLinkNode(styles.hyperlink, openLinksInNewTab),
      ...getInlineAssetNodes(),
      ...getInlineEntryNodes(inlineEntryRenderer),
    },
    renderText: (text) => (inlineTextEntryRenderer ? inlineTextEntryRenderer(text) : text),
  }
  return <Box __css={styles.richText}>{documentToReactComponents(document, options)}</Box>
}

export default RichText
