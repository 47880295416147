import { helpers } from './base'

export const PopularDestinations = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      '> h4': {
        fontSize: 'lg',
        fontWeight: 'var(--alto-sem-text-body-bold-font-weight)',
        marginBottom: {
          base: 'lg',
          lg: 0,
        },
      },
    },
    destinationCard: {
      borderRadius: 'xs',
      h4: {
        fontSize: 'lg',
        fontWeight: 'normal',
      },
      p: {
        fontWeight: 'light',
      },
      boxShadow: 'none',
      background: 'bg.layer1.default',
      _hover: {
        h4: {
          color: 'fg.accent.primary',
        },
      },
    },
    thumbnail: {
      borderRadius: 'xs',
    },
  },
})
