/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const black = 'fg.primary'

export const CustomerReviewList = helpers.defineMultiStyleConfig({
  baseStyle: {
    contentWrapper: {
      color: black,
    },
    customerReviewsCtn: {
      rowGap: 'var(--alto-sem-space-sm)',
    },
    customerReviewCtn: {
      bgColor: 'bg.layer2.default',
      borderRadius: 'lg',
    },
    title: {
      mt: 'var(--alto-sem-space-sm)',
      mb: 'var(--alto-sem-space-sm)',
    },
    ratingsCtn: {
      small: {
        fontSize: {
          base: 'xs',
          md: 'sm',
        },
      },
    },
    icons: {
      color: black,
      fontSize: {
        base: 'xs',
        lg: 'sm',
      },
    },
    icon: {
      color: black,
    },
    reviewText: {
      fontSize: {
        base: 'md',
        lg: 'lg',
      },
      color: 'fg.secondary',
    },
    showMoreButton: {
      fontSize: 'lg',
      fontWeight: 'normal',
    },
    readMoreButton: {
      fontSize: 'sm',
    },
  },
})
