import React from 'react'
import { createDatadogLogger } from '~/utils/logger'
import { datadogRum } from '~/utils/datadog'
import { createDatadogMetaContext } from '@reward-platform/logger/utils'
import { GenericClientError } from '~/utils/errors'
import ErrorBoundaryPage from './ErrorBoundaryPage'
import { ErrorBoundaryProps } from './ErrorBoundary.types'

export interface State {
  error: Error | null
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { error: null }
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const renderingError = new Error(error.message)
    renderingError.name = `ReactRenderingError`
    renderingError.stack = errorInfo.componentStack ?? undefined
    renderingError.cause = error
    datadogRum.addError(renderingError)

    const {
      partner: { code: partnerCode },
    } = this.props

    if (error instanceof GenericClientError) {
      error.logToDatadog({ partner: partnerCode })
    } else {
      const context = { ...errorInfo, ...createDatadogMetaContext({ partner: partnerCode }) }
      const logger = createDatadogLogger('react-error-boundary')
      logger.error(error.message, context, renderingError)
    }
  }

  private reset() {
    this.setState({ error: null })
  }

  public render() {
    const { error } = this.state
    const { children, partner } = this.props

    if (error) {
      return <ErrorBoundaryPage partner={partner} error={error} reset={this.reset} />
    }

    return children
  }
}

export default ErrorBoundary
